import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { cn } from "lib/utils"
import { buildInitialDraft } from "utils/mailbox"
import { useAppDispatch, useAppSelector, useTranslation } from "hooks"
import { Bot, RefreshCcw, MailPlus } from "lucide-react"
import { GENERAL_CONVERSATION_ID, MAILBOX_TYPE, REPLY_TYPE, RoleEnum, THREAD_LIST_LIMIT } from "const"
import { ROUTES } from "routing/constants"
import { selectActiveMailboxConnectedEmail } from "store/users"
import { selectProfileRole } from "store/profile"
import { getConversationId, selectIntercomThreadConversationPairs } from "store/chat"
import { mailboxActions, getThreadsList, getDraftsList, getArchivedThreadsList, selectThreadsListIsLoading } from "store/mailbox"
import { selectGeneralConversationRecordHasUnread } from "store/notifications"
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip"
import { Separator } from "components/ui/separator"
import { ThreadsList } from "./ThreadsList"
import { Badge } from "components/ui/badge"

interface IProps {
  type: MAILBOX_TYPE
}

export function ThreadsListPanel({ type = MAILBOX_TYPE.INBOX }: IProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;

  const threadsListIsLoading = useSelector(selectThreadsListIsLoading);
  const connectedEmail = useAppSelector(selectActiveMailboxConnectedEmail);
  const profileRole = useAppSelector(selectProfileRole);
  const intercomConversationIds = useAppSelector(selectIntercomThreadConversationPairs);
  const hasGeneralChatUnreadMsgs = useAppSelector(selectGeneralConversationRecordHasUnread);

  const isAdvisor = RoleEnum.Advisor === profileRole;

  const handleRefresh = async () => {
    const payload = { limit: THREAD_LIST_LIMIT, offset: 0 };

    await dispatch(mailboxActions.resetThreadsAndSelectedThread());

    if (isAdvisor) {
      await dispatch(mailboxActions.setIsRefreshingMailbox(true));
      await dispatch(getThreadsList(payload));
    } else {
      if (pathname.includes(ROUTES.PARAPLANNER.MAILBOX.INBOX)) {
        await dispatch(mailboxActions.setIsRefreshingMailbox(true));
        await dispatch(getThreadsList(payload));
      }
      if (pathname.includes(ROUTES.PARAPLANNER.MAILBOX.DRAFTS)) {
        await dispatch(mailboxActions.setIsRefreshingMailbox(true));
        await dispatch(getDraftsList(payload));
      }
      if (pathname.includes(ROUTES.PARAPLANNER.MAILBOX.ARCHIVED)) {
        await dispatch(mailboxActions.setIsRefreshingMailbox(true));
        await dispatch(getArchivedThreadsList(payload));
      }
    }

  }

  const handleCreateDraft = async () => {
    const draftEmail = buildInitialDraft({ type: REPLY_TYPE.REPLY_TO_ALL, connectedEmail });
    await dispatch(mailboxActions.createDraft({ draft: draftEmail }));
    handleRefresh();
  }

  const onOpenGeneralConversation = async () => {
    dispatch(mailboxActions.updateSelectedThread(null));
    await dispatch(getConversationId({ threadId: GENERAL_CONVERSATION_ID, intercomConversationIds, forceIsOpen: true }));
  }

  return (
    <>
      <div className="flex items-center px-4 py-2 min-h-14">
        <h1 className="text-xl font-bold">{t('mailbox.mailbox')}</h1>

        <div className="ml-auto flex gap-2">
          {isAdvisor && (
            <Badge
              variant="outline"
              className='p-2'
              onClick={onOpenGeneralConversation}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <Bot
                    className={cn("h-4 w-4 text-blue-600", { 'animate-bounce': hasGeneralChatUnreadMsgs })}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  {hasGeneralChatUnreadMsgs ? t('common.chatWithUsUnread') : t('common.chatWithUs')}
                </TooltipContent>
              </Tooltip>
            </Badge>
          )}

          <Badge
            variant="outline" className='p-2'
            onClick={() => handleRefresh()}
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <RefreshCcw className={cn("h-4 w-4", { 'animate-spin': threadsListIsLoading })} />
              </TooltipTrigger>
              <TooltipContent>
                {t('mailbox.refresh')}
              </TooltipContent>
            </Tooltip>
          </Badge>

          {!isAdvisor && (
            <Badge
              variant="outline" className={'p-2'}
              onClick={handleCreateDraft}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <MailPlus className="h-4 w-4" />
                </TooltipTrigger>
                <TooltipContent>
                  {t('mailbox.createDraft')}
                </TooltipContent>
              </Tooltip>
            </Badge>
          )}
        </div>
      </div>

      <Separator />

      <ThreadsList type={type} />
    </>
  )
}
