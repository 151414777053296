import { cn } from 'lib/utils';
import { formatPreviewDate } from 'utils/date';
import { Bot, Pencil } from 'lucide-react';
import { IEmailThread } from 'store/mailbox';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { useAppSelector, useTranslation } from 'hooks';
import { Badge } from 'components/ui/badge';
import { selectProfileRole } from 'store/profile';
import { RoleEnum } from 'const';
export interface IThreadPreviewProps {
  thread: IEmailThread;
  isActive: boolean;
};

export const ThreadsListItem = ({ thread, isActive }: IThreadPreviewProps) => {
  const { t } = useTranslation();
  const profileRole = useAppSelector(selectProfileRole);

  const getName = () => {
    if (thread?.messages?.length > 0) {
      const fromContact = thread?.messages?.[0]?.from?.[0] || thread?.drafts?.[0]?.from?.[0];
      return fromContact?.name || fromContact?.email || '-';
    }

    if (thread?.drafts?.length > 0) {
      return '[Draft]';
    }

    return '-'

  }

  const getDateAndTime = () => {
    const lastMessage = thread?.messages[thread?.messages?.length - 1];
    const lastDraft = thread?.drafts[thread?.drafts?.length - 1];

    if (lastMessage) {
      return formatPreviewDate(
        new Date(Math.floor(lastMessage?.date * 1000))
      )
    }

    if (lastDraft) {
      return formatPreviewDate(
        new Date(Math.floor(lastDraft?.date * 1000))
      )
    }

    return '-'
  }


  return (
    <div className='w-full'>
      <button
        className={cn(
          "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent w-full",
          isActive && "bg-muted"
        )}
      >
        <div className="flex w-full flex-col gap-1">
          <div className="flex items-center">
            <div className="flex items-center gap-2">

              <div className="font-semibold line-clamp-1">
                {getName()}
              </div>

              {!!thread?.messages?.length && thread?.messages?.length > 1 && (
                <span className='font-normal'>
                  ({thread.messages?.length})
                </span>
              )}

              {thread?.unread && (
                <span className="flex h-2 w-2 rounded-full bg-blue-600" />
              )}
            </div>

            <div
              className={cn(
                "ml-auto text-xs flex shrink-0 items-center",
                isActive ? "text-foreground" : "text-muted-foreground"
              )}
            >
              {getDateAndTime()}
            </div>
          </div>
          <div className="text-xs font-medium">
            {thread?.subject || '(no subject)'}</div>
        </div>

        <div className="line-clamp-2 text-xs text-muted-foreground">
          {thread?.snippet || thread?.drafts?.[0]?.snippet || '(no body)'}
        </div>

        <div className="flex items-center gap-2">
          {profileRole === RoleEnum.Advisor && (
            <>
              {thread.has_ai_chat && !thread.has_ai_chat_with_unread && (
                <Badge variant="outline" className='p-2'>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Bot className="h-4 w-4" />
                    </TooltipTrigger>
                    <TooltipContent>
                      {t('common.aiConversation')}
                    </TooltipContent>
                  </Tooltip>
                </Badge>
              )}

              {thread.has_ai_chat_with_unread && (
                <Badge variant="outline" className='p-2'>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Bot className="h-4 w-4 animate-bounce text-blue-600" />
                    </TooltipTrigger>
                    <TooltipContent>
                      {t('common.aiConversationWithMessages')}
                    </TooltipContent>
                  </Tooltip>
                </Badge>
              )}
            </>
          )}


          {!!(thread && thread?.drafts?.length) && (
            <Badge variant="outline" className='p-2'>
              <Tooltip >
                <TooltipTrigger asChild>
                  <Pencil className="h-4 w-4" />
                </TooltipTrigger>
                <TooltipContent>
                  {t('component.MailThreadPreview.hasDraft')}
                </TooltipContent>
              </Tooltip>
            </Badge>
          )}
        </div>
      </button>
    </div>
  );
}



