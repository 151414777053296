import { formatPreviewDate } from 'utils/date.js';
import { messageHasAttachments } from 'utils/mailbox';
import { useAppSelector, useTranslation } from 'hooks';
import { IEmail, selectSelectedThread } from 'store/mailbox';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { Pencil, Paperclip } from 'lucide-react';
import { EmailTypingWidget } from './EmailTypingWidget';

interface IProps {
  message?: IEmail;
  isMessageExpanded?: boolean;
};

export const EmailInfo = ({ message, isMessageExpanded }: IProps) => {
  const { t } = useTranslation();
  const activeThread = useAppSelector(selectSelectedThread);
  const messageHasDraft = !!(message && activeThread?.drafts.find(draft => draft.reply_to_message_id === message.id));

  const getName = () => {
    const fromContact = message?.from?.[0];
    return fromContact?.name || fromContact?.email || 'Unknown';
  }

  const getEmail = () => {
    if (message && isMessageExpanded) {
      const fromContact = message?.from?.[0];
      return fromContact?.email;
    }
    return '';
  }

  const getDateAndTime = () => {
    if (message) {
      return formatPreviewDate(
        new Date(Math.floor(message?.date * 1000))
      )
    }
    return '';
  }

  return (
    <div className="flex items-start gap-4 justify-between">
      <div className="flex items-start gap-4 text-sm">
        <Avatar>
          <AvatarImage alt={getName()} />
          <AvatarFallback>
            {getName()
              ?.split(" ")
              .map((chunk) => chunk[0])
              .join("")}
          </AvatarFallback>
        </Avatar>
        <div className="grid gap-1">
          <div className="font-semibold">
            {getName()}
            {isMessageExpanded && (
              <span className="ml-2 text-muted-foreground text-sm font-light">
                {getEmail()}
              </span>
            )}
          </div>

          {!isMessageExpanded ? (
            <div className="line-clamp-1 text-xs text-muted-foreground break-all">
              {message?.snippet || '(no body)'}
            </div>
          ) : (
            <>
              <EmailTypingWidget
                label={t('mailbox.toLabel')}
                recipients={message?.to || []}
              />
              {
                (message?.cc && message?.cc?.length > 0) && (<EmailTypingWidget
                  label={t('mailbox.ccLabel')}
                  recipients={message?.cc || []}
                />)
              }
            </>
          )}
        </div>
      </div>

      <div className="text-xs text-muted-foreground flex items-center gap-2 shrink-0">
        {messageHasDraft && <Pencil className="w-4 h-4" />}
        {(messageHasAttachments(message)) && <Paperclip className="w-4 h-4" />}
        {getDateAndTime()}
      </div>
    </div>
  );
}

