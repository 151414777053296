import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { CirclePlus } from 'lucide-react';
import { cn } from 'lib/utils';
import { Card, CardHeader } from 'components/ui/card';
import { CardTitle } from 'components/ui/card';
import { Loading } from 'components/layout-atoms/Loading';
import { Fieldset } from 'components/ui/fieldset';
import { setActiveUser, getUsersList, selectActiveUser, selectIsGetUsersListProcessing, selectUsersList, IUser, deleteUser, selectIsUserOperationProcessing, INewAddedUser } from 'store/users';
import { ItemSelectedIcon } from 'components/common/_atoms/ItemSelectedIcon';
import { UserModal } from './UserModal';
import { DetailsLine } from 'components/common/_atoms/DetailsLine';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';
import { ConfirmActionDialog } from 'components/common/_atoms/ConfirmActionDialog';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { toast } from 'react-toastify';
import { ScrollArea } from 'components/ui/scroll-area';
import { SearchInputDeprecated } from 'components/common/_atoms/SearchInputDeprecated';


export const UserManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [userModalData, setUserModalData] = useState<IUser | null>();

  const usersList = useAppSelector(selectUsersList);
  const activeUser = useAppSelector(selectActiveUser);

  const isGetUsersListProcessing = useAppSelector(selectIsGetUsersListProcessing);
  const isUserOperationProcessing = useAppSelector(selectIsUserOperationProcessing);

  const [searchValue, setSearchValue] = useState('')
  const [displayList, setDisplayList] = useState<IUser[]>([])

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (!searchValue) {
      setDisplayList(usersList)
    } else {
      const lowerCaseValue = searchValue?.toLowerCase();

      setDisplayList(usersList.filter(item => {
        const fullName = `${item?.firstName} ${item?.lastName}`.toLowerCase();
        return fullName?.includes(lowerCaseValue) || item?.email?.toLowerCase().includes(lowerCaseValue)
      }))
    }
  }, [usersList, searchValue]);

  const handleDeleteUser = async () => {
    if (!activeUser) return;

    const response = await dispatch(deleteUser(activeUser));

    if (!response?.hasOwnProperty('error')) {
      toast(t(`component.UserManagement.deleteSuccess`))
      setIsDeleteConfirmVisible(false)
    }
  }

  const handleOnCreateUpdateComplete = async (user: INewAddedUser) => {
    const response = await dispatch(getUsersList());
    const payload = response?.payload as IUser[];
    if (payload) {
      const theNewUser = payload.find(responseUser => responseUser?.id === user?.id)
      if (theNewUser) {
        dispatch(setActiveUser(theNewUser))
      }
    }
  }

  return (
    <Fragment>
      <div className="flex flex-1 overflow-auto gap-6">
        <Fieldset
          className='basis-2/4'
          legend={t('component.UserManagement.users')}
          fieldsetActions={[
            {
              onClick: () => {
                setIsUserModalVisible(true)
                setUserModalData(null)
              },
              tooltip: t('component.UserManagement.addUser'),
              content: <CirclePlus className='size-4' />
            }
          ]}
        >
          <SearchInputDeprecated
            inputPlaceholder={t('component.UserManagement.searchPlaceholder') as string}
            onClearSearch={() => setSearchValue('')}
            onChange={(value) => setSearchValue(value)}
          // containerClassName="p-0 pr-3"
          />

          <ScrollArea className="grow overflow-auto">
            <div className='flex flex-col gap-3 pr-3'>
              {
                !isGetUsersListProcessing ?
                  displayList?.map(user => {
                    const isActive = user?.id === activeUser?.id;

                    return (
                      <Card
                        key={user.id}
                        variant="item"
                        className={cn('cursor-pointer', { 'border-primary': isActive })}
                        onClick={() => dispatch(setActiveUser(user))}
                      >
                        <CardHeader className="p-3 break-all">
                          <CardTitle className="text-sm">
                            {user?.firstName} {user?.lastName}
                            <span className="text-muted-foreground ml-1">({user?.email})</span>
                          </CardTitle>
                        </CardHeader>

                        {isActive && <ItemSelectedIcon />}
                      </Card>
                    )
                  })
                  : (
                    <Loading variant="item" />
                  )
              }
            </div>
          </ScrollArea>
        </Fieldset>

        <Fieldset
          className='basis-2/4'
          legend={t('component.UserManagement.userDetails')}
        >
          {
            activeUser ? (
              <>
                {isGetUsersListProcessing ? (
                  <>
                    <Loading variant="centered" />
                  </>
                ) : (
                  <>
                    <div className='flex flex-col gap-4'>
                      <div className="grid gap-2">
                        <DetailsLine label={t('component.UserManagement.firstName')} value={activeUser?.firstName} />
                        <DetailsLine label={t('component.UserManagement.lastName')} value={activeUser?.lastName} />
                        <DetailsLine label={t('component.UserManagement.email')} value={activeUser?.email} />
                        <DetailsLine label={t('component.UserManagement.role')} value={activeUser?.role} />
                        <Separator />
                        <DetailsLine label={t('component.UserManagement.organizationName')} value={activeUser?.organisations?.[0]?.organisationName} />
                        <DetailsLine label={t('component.UserManagement.organizationRole')} value={activeUser?.organisations?.[0]?.role} />
                        <DetailsLine label={t('component.UserManagement.organizationIndustryType')} value={activeUser?.organisations?.[0]?.industryType} />
                      </div>
                      <Separator />
                      <div className="flex gap-3 justify-end">
                        <Button variant="destructive" onClick={() => setIsDeleteConfirmVisible(true)}>
                          {t('component.UserManagement.deleteBtn')}
                        </Button>
                        <Button onClick={() => {
                          setIsUserModalVisible(true)
                          setUserModalData(activeUser)
                        }}>
                          {t('component.UserManagement.editBtn')}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <InfoCard
                variant="item"
                title={t('component.UserManagement.noActiveUser')}
                description={t('component.UserManagement.noActiveUserDesc') as string}
              />
            )
          }
        </Fieldset>
      </div >

      <UserModal
        isVisible={isUserModalVisible}
        setIsVisible={setIsUserModalVisible}
        data={userModalData}
        onComplete={handleOnCreateUpdateComplete}
      />

      {activeUser &&
        <ConfirmActionDialog
          onOk={handleDeleteUser}
          isVisible={isDeleteConfirmVisible}
          setIsVisible={setIsDeleteConfirmVisible}
          isProcessing={isUserOperationProcessing}
          title={t('component.UserManagement.deleteConfirmTitle')?.replace('{{clientName}}', `${activeUser?.firstName} ${activeUser?.lastName} (${activeUser?.email})`)}
          description={t('component.UserManagement.deleteConfirmDescription')}
        />
      }
    </Fragment >
  );
};