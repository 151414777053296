import { createSlice, current, isAnyOf } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  IMailboxState,
  IThreadsListState,
  IUpdateThreadChatIndicatorsPayload,
  IEmail,
  IEmailThread,
} from "./types";
import {
  getThreadsList,
  getMessage,
  getEmailAttachment,
  updateDraft,
  uploadFiles,
  deleteFile,
  getThreadDetails,
  createDraft,
  deleteDraft,
  getDraft,
  updateThreadLabel,
  getDraftsList,
  getArchivedThreadsList,
  deleteThreadLabel,
  getThreadsWithConversationList,
  searchThreadsList,
} from "./actions";
import { THREAD_LIST_LIMIT } from "const";

const initialThreadsListState: IThreadsListState = {
  data: null,
  hasMoreData: true,
  offset: 0,
  isLoading: false,
};

const initialState: IMailboxState = {
  executingActionOnThreadId: null,
  loadingThreadMessageId: null,
  loadingGetAttachment: false,
  gettingDraft: false,
  updatingDraft: false,
  creatingDraft: false,
  deletingDraft: false,
  downloadingFile: false,
  uploadingFiles: false,
  deletingFiles: false,
  loadingGetThreadDetails: false,
  isRefreshingMailbox: false,
  threadsList: initialThreadsListState,
  selectedThread: null,
  expandedMessageIds: [],
};

const mailboxSlice = createSlice({
  name: "mailbox",
  initialState,
  reducers: {
    updateSelectedThread: (
      state,
      action: PayloadAction<IEmailThread | null>
    ) => {
      const { payload } = action;
      if (!payload) {
        state.selectedThread = null;
      } else {
        state.selectedThread = { ...state.selectedThread, ...payload };
      }
    },
    updateThreadDrafts: (state, action: PayloadAction<IEmailThread>) => {
      if (!state.selectedThread) return;
      const payload = action.payload;

      state.selectedThread = {
        ...state.selectedThread,
        drafts: payload.drafts,
      };
    },
    updateThreadMessage: (state, action: PayloadAction<IEmail>) => {
      state.loadingThreadMessageId = null;

      if (!state.selectedThread) return;

      const payload = action.payload;
      const updatedMessages = state.selectedThread?.messages?.map((msg) =>
        msg.id === payload?.id ? payload : msg
      );

      state.selectedThread = {
        ...state.selectedThread,
        messages: updatedMessages,
      };
    },

    addIdToExpandedMessages: (state, action: PayloadAction<string>) => {
      const msgId = action.payload;

      if (!state?.expandedMessageIds?.includes(msgId)) {
        state.expandedMessageIds = [...state.expandedMessageIds, msgId];
      }
    },
    removeIdFromExpandedMessages: (state, action: PayloadAction<string>) => {
      const msgId = action.payload;

      if (state?.expandedMessageIds.includes(msgId)) {
        state.expandedMessageIds = state.expandedMessageIds.filter(
          (currentId) => currentId !== msgId
        );
      }
    },
    resetExpandedMessages: (state) => {
      state.expandedMessageIds = [];
    },
    propagateDraftUpdates: (state, action: PayloadAction<IEmail[]>) => {
      const updatedDrafts = action.payload;
      if (!state.selectedThread) return;

      state.selectedThread = {
        ...state.selectedThread,
        drafts: updatedDrafts,
      };

      // Update threads list with draft
      if (state.threadsList && state.threadsList.data) {
        state.threadsList.data = state.threadsList?.data?.map((thread) => {
          if (thread.thread_id === state.selectedThread?.thread_id) {
            return {
              ...thread,
              drafts: updatedDrafts,
            };
          }
          return thread;
        });
      }
    },
    updateThreadChatIndicators: (
      state,
      action: PayloadAction<IUpdateThreadChatIndicatorsPayload>
    ) => {
      const payload = action.payload;

      if (state.threadsList && state.threadsList.data && payload?.thread_id) {
        state.threadsList.data = state.threadsList?.data?.map((thread) => {
          if (thread.thread_id === payload?.thread_id) {
            return {
              ...thread,
              ...payload,
            };
          }
          return thread;
        });
      }
    },
    setIsRefreshingMailbox: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingMailbox = action.payload;
    },
    resetThreadsAndSelectedThread: (state) => {
      state.isRefreshingMailbox = false;
      state.selectedThread = null;
      state.threadsList = initialThreadsListState;
      state.expandedMessageIds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThreadDetails.pending, (state) => {
        state.loadingGetThreadDetails = true;
      })
      .addCase(getThreadDetails.fulfilled, (state, action) => {
        state.loadingGetThreadDetails = false;
        mailboxSlice.caseReducers.resetExpandedMessages(state);
        mailboxSlice.caseReducers.updateThreadDrafts(state, action);
      })
      .addCase(getThreadDetails.rejected, (state, action) => {
        state.loadingGetThreadDetails = false;
      })
      .addCase(getMessage.pending, (state, action) => {
        state.loadingThreadMessageId = action.meta.arg.id;
      })
      .addCase(getMessage.fulfilled, (state, action) => {
        state.loadingThreadMessageId = null;

        mailboxSlice.caseReducers.updateThreadMessage(state, {
          ...action,
          payload: action.payload,
        });

        mailboxSlice.caseReducers.addIdToExpandedMessages(state, {
          ...action,
          payload: action.payload?.id,
        });
      })
      .addCase(getMessage.rejected, (state) => {
        state.loadingThreadMessageId = null;
      })
      .addCase(getDraft.pending, (state, action) => {
        state.gettingDraft = true;
      })
      .addCase(getDraft.fulfilled, (state, action) => {
        state.gettingDraft = false;
        if (!state.selectedThread) return;

        const updatedDrafts = state.selectedThread?.drafts?.map((draft) =>
          draft.id === action.payload.id ? action.payload : draft
        );

        mailboxSlice.caseReducers.propagateDraftUpdates(state, {
          ...action,
          payload: updatedDrafts,
        });
      })
      .addCase(getDraft.rejected, (state) => {
        state.gettingDraft = false;
      })

      .addCase(createDraft.pending, (state, action) => {
        state.creatingDraft = true;
      })
      .addCase(createDraft.fulfilled, (state, action) => {
        state.creatingDraft = false;
        if (!state.selectedThread) return;

        const updatedDrafts = [...state.selectedThread.drafts, action.payload];

        mailboxSlice.caseReducers.propagateDraftUpdates(state, {
          ...action,
          payload: updatedDrafts,
        });
      })
      .addCase(createDraft.rejected, (state) => {
        state.creatingDraft = false;
      })
      .addCase(updateDraft.pending, (state, action) => {
        state.updatingDraft = true;
      })
      .addCase(updateDraft.fulfilled, (state, action) => {
        toast("Draft updated");
        const currentState = current(state);
        const payloadDraft = action.meta.arg.draft;
        const isStandaloneDraft = !payloadDraft?.replyToMessageId;
        const newDraft = action.payload;
        state.updatingDraft = false;

        if (!state.selectedThread) return;

        const updatedDrafts = currentState.selectedThread?.drafts?.map(
          (draft) =>
            draft.reply_to_message_id === newDraft?.reply_to_message_id
              ? newDraft
              : draft
        );
        if (!isStandaloneDraft && updatedDrafts) {
          mailboxSlice.caseReducers.propagateDraftUpdates(state, {
            ...action,
            payload: updatedDrafts,
          });
        }

        // If it is standalone draft, the thread list needs to be updated
        if (isStandaloneDraft && state.threadsList && state.threadsList.data) {
          const originalThreadId = payloadDraft.thread_id;

          if (currentState.threadsList?.data) {
            const updatedThreadsList = currentState.threadsList?.data?.map(
              (thread) => {
                if (thread.thread_id === originalThreadId) {
                  return {
                    ...thread,
                    ...{ ...newDraft, labels: [] },
                  };
                }
                return thread;
              }
            );

            state.threadsList = {
              ...currentState.threadsList,
              data: updatedThreadsList,
            };
          }
        }
      })
      .addCase(updateDraft.rejected, (state) => {
        state.updatingDraft = false;
      })
      .addCase(deleteDraft.pending, (state, action) => {
        state.deletingDraft = true;
      })
      .addCase(deleteDraft.fulfilled, (state, action) => {
        state.deletingDraft = false;
        if (!state.selectedThread) return;

        const updatedDrafts = state.selectedThread?.drafts?.filter(
          (draft) => draft.id !== action.meta.arg.id
        );

        mailboxSlice.caseReducers.propagateDraftUpdates(state, {
          ...action,
          payload: updatedDrafts,
        });
        toast("Draft deleted!");
      })
      .addCase(deleteDraft.rejected, (state) => {
        state.deletingDraft = false;
      })
      .addCase(getEmailAttachment.pending, (state, action) => {
        state.downloadingFile = true;
      })
      .addCase(getEmailAttachment.fulfilled, (state, action) => {
        state.downloadingFile = false;
      })
      .addCase(getEmailAttachment.rejected, (state) => {
        state.downloadingFile = false;
      })
      .addCase(uploadFiles.pending, (state, action) => {
        state.uploadingFiles = true;
      })
      .addCase(uploadFiles.fulfilled, (state, action) => {
        state.uploadingFiles = false;
      })
      .addCase(uploadFiles.rejected, (state) => {
        state.uploadingFiles = false;
        toast("Error uploading file");
      })
      .addCase(deleteFile.pending, (state, action) => {
        state.deletingFiles = true;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.deletingFiles = false;
        const outdatedDraftId = action.meta.arg.messageId;
        const newDraft = action.payload;

        if (!state.selectedThread || !newDraft) return;

        const updatedDrafts = state.selectedThread?.drafts.map((draft) =>
          draft.id === outdatedDraftId ? newDraft : draft
        );

        mailboxSlice.caseReducers.propagateDraftUpdates(state, {
          ...action,
          payload: updatedDrafts,
        });
      })
      .addCase(deleteFile.rejected, (state) => {
        state.deletingFiles = false;
      })
      .addCase(searchThreadsList.pending, (state, action) => {
        state.threadsList.isLoading = true;
        state.threadsList.data = [];
      })
      .addCase(searchThreadsList.fulfilled, (state, action) => {
        const fetchedItems = action?.payload || [];
        state.threadsList.isLoading = false;
        state.isRefreshingMailbox = false;
        state.threadsList.data = fetchedItems;
        state.threadsList.offset = 0;
        state.threadsList.hasMoreData = false;
      })
      .addCase(searchThreadsList.rejected, (state) => {
        mailboxSlice.caseReducers.resetThreadsAndSelectedThread(state);
      })
      .addMatcher(
        isAnyOf(
          getThreadsList.pending,
          getDraftsList.pending,
          getArchivedThreadsList.pending,
          getThreadsWithConversationList.pending
        ),
        (state, action) => {
          state.threadsList.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getThreadsList.fulfilled,
          getDraftsList.fulfilled,
          getArchivedThreadsList.fulfilled,
          getThreadsWithConversationList.fulfilled
        ),
        (state, action) => {
          state.threadsList.isLoading = false;
          state.isRefreshingMailbox = false;
          const params = action.meta.arg;
          const offset = params.offset;
          const fetchedItems = action?.payload || [];

          if (offset !== 0) {
            state.threadsList.data = [
              ...(state.threadsList.data ? state.threadsList.data : []),
              ...fetchedItems,
            ];
          } else {
            state.threadsList.data = fetchedItems;
          }

          const updatedOffset = offset + THREAD_LIST_LIMIT;

          state.threadsList.offset = updatedOffset;
          if (fetchedItems?.length === 0) {
            state.threadsList.hasMoreData = false;
          } else {
            state.threadsList.hasMoreData = fetchedItems?.[0]?.hasMoreData;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          getThreadsList.rejected,
          getDraftsList.rejected,
          getArchivedThreadsList.rejected,
          getThreadsWithConversationList.rejected
        ),
        (state) => {
          mailboxSlice.caseReducers.resetThreadsAndSelectedThread(state);
        }
      )
      .addMatcher(
        isAnyOf(updateThreadLabel.pending, deleteThreadLabel.pending),
        (state, action) => {
          state.executingActionOnThreadId = action.meta.arg.threadId;
        }
      )
      .addMatcher(
        isAnyOf(updateThreadLabel.fulfilled, deleteThreadLabel.fulfilled),
        (state, action) => {
          state.executingActionOnThreadId = null;
          const updatedThreadId = action.meta.arg.threadId;
          toast("Thread updated");

          // Update threads list with draft
          if (state.threadsList && state.threadsList.data) {
            state.threadsList.data = state.threadsList?.data?.map((thread) => {
              if (thread.thread_id === updatedThreadId) {
                return { ...thread, labels: action.payload?.labels };
              }
              return thread;
            });
          }

          if (state.selectedThread?.thread_id === updatedThreadId) {
            state.selectedThread = null;
          }
        }
      )
      .addMatcher(
        isAnyOf(updateThreadLabel.rejected, deleteThreadLabel.rejected),
        (state) => {
          state.executingActionOnThreadId = null;
        }
      );
  },
});

export const mailboxActions = {
  ...mailboxSlice.actions,
  getMessage,
  getDraft,
  getThreadsList,
  getEmailAttachment,
  updateDraft,
  uploadFiles,
  deleteFile,
  getThreadDetails,
  createDraft,
  deleteDraft,
  updateThreadLabel,
  deleteThreadLabel,
};

export default mailboxSlice.reducer;
