import { Inbox, SquarePen, Archive } from "lucide-react"
import { Separator } from "components/ui/separator"
import { MailboxNavigation } from "./MailboxNavigation"
import { AccountSwitcher } from "./AccountSwitcher"
import { cn } from "lib/utils"
import { useAppSelector, useTranslation } from "hooks"
import { RoleEnum } from "const"
import { selectProfileRole } from "store/profile"
import { ROUTES } from "routing/constants"
import { useLocation } from "react-router-dom"

interface IProps {
  isCollapsed: boolean
}

export function NavigationPanel({ isCollapsed }: IProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  return (

    <>
      <div
        className={cn(
          "flex h-[52px] items-center justify-center min-h-14",
          isCollapsed ? "h-[52px]" : "px-2"
        )}
      >
        <AccountSwitcher isCollapsed={isCollapsed} />
      </div>

      <Separator />

      <MailboxNavigation
        isCollapsed={isCollapsed}
        links={[
          {
            title: t('mailbox.inbox'),
            label: "",
            icon: Inbox,
            variant: pathname.includes(ROUTES.PARAPLANNER.MAILBOX.INBOX) || pathname === ROUTES.PARAPLANNER.MAILBOX.DEFAULT ? "default" : "ghost",
            to: ROUTES.PARAPLANNER.MAILBOX.INBOX,
          },
          {
            title: t('mailbox.drafts'),
            label: "",
            icon: SquarePen,
            variant: pathname.includes(ROUTES.PARAPLANNER.MAILBOX.DRAFTS) ? "default" : "ghost",
            to: ROUTES.PARAPLANNER.MAILBOX.DRAFTS,
          },
          {
            title: t('mailbox.archived'),
            label: "",
            icon: Archive,
            variant: pathname.includes(ROUTES.PARAPLANNER.MAILBOX.ARCHIVED) ? "default" : "ghost",
            to: ROUTES.PARAPLANNER.MAILBOX.ARCHIVED,
          }
        ]}
      />
    </>
  )
}
