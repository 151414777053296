import { useEffect, useState } from 'react';
import { useNylas } from '@nylas/nylas-react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { clearNylasAuthUrlValue, exchangeCodeFromUrlForToken, getNylasAuthUrl, selectIsNylasProcessing, selectNylasConnectionEstablished } from 'store/nylas';
import { useSearchParams } from 'react-router-dom';
import { getProfileDetails, selectProfileDetails } from 'store/profile';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { ConnectNylasModal } from 'components/common/app/_general/ConnectNylasModal';
import { Loading } from 'components/layout-atoms/Loading';

export interface INylasForm {
  email: string;
}

export const ConnectEmailNylas = () => {
  const { t } = useTranslation();
  const nylas = useNylas();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const profileDetails = useAppSelector(selectProfileDetails)
  const isNylasProcessing = useAppSelector(selectIsNylasProcessing);
  const nylasAuthUrl = useAppSelector(getNylasAuthUrl);
  const nylasConnectionEstablished = useAppSelector(selectNylasConnectionEstablished);

  const { connectedEmail: emailIsConnected } = profileDetails;

  const isActionProcessing = isNylasProcessing || !!nylasAuthUrl;
  const isNylasSetupDone = emailIsConnected || nylasConnectionEstablished;
  const isProfileFetched = profileDetails?.email?.length > 0;

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      setIsModalVisible(false)
    }
  }, [])

  const handleConnectNylas = () => {
    setIsModalVisible(true)
  }


  // Redirect to Nylas to connect email on their side
  useEffect(() => {
    if (nylasAuthUrl) {
      const redirectUrl = nylasAuthUrl;
      dispatch(clearNylasAuthUrlValue());
      window.location.href = redirectUrl;
    }
  }, [nylasAuthUrl, dispatch])


  // Handle the code that is passed in the query params from Nylas after a successful login
  useEffect(() => {
    if (!nylas) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      params.delete('code');
      setSearchParams(params);

      dispatch(exchangeCodeFromUrlForToken({ token: code }));
    }
  }, [nylas]);

  useEffect(() => {
    if (nylasConnectionEstablished) {
      dispatch(getProfileDetails())
    }
  }, [nylasConnectionEstablished, dispatch]);

  if (!isProfileFetched) {
    return (
      <Loading />
    );
  }

  return (
    <div className="flex flex-row items-center justify-between gap-6">
      <div className="space-y-1.5">
        <Label>
          {t('component.ConnectEmailNylas.title')}
        </Label>
        <Label variant="description">
          {!isNylasSetupDone ? (
            t('component.ConnectEmailNylas.descriptionConnect')
          ) : (
            t('component.ConnectEmailNylas.descriptionDisconnect')
          )}
        </Label>
      </div>

      {!isNylasSetupDone ? (
        <Button
          type='button'
          onClick={handleConnectNylas}
          loading={isActionProcessing}
        >
          {t('general.connect')}
        </Button>
      ) : (
        <>
          <a
            href={`mailto:${'supportme@paraplanner.ai'}?subject=Disconnect email`}
            title={t('general.contactSupport') as string}
            rel="noreferrer"
            id="link"
          >
            <Button
              type='button'
              variant="destructive"
            >
              {t('general.contactSupport')}
            </Button>
          </a>
        </>
      )}

      <ConnectNylasModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
      // onCreateCompleted={onConnectCompleted}
      />
    </div>
  );
};

