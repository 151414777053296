import { MailboxManager } from 'components/_mailbox/MailboxManager';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { MAILBOX_TYPE, RoleEnum } from 'const';
import { useAppSelector } from 'hooks';
import { cn } from 'lib/utils';
import { selectProfileRole } from 'store/profile';

interface IProps {
  type: MAILBOX_TYPE
}

export const MailboxesPage = ({ type }: IProps) => {
  const profileRole = useAppSelector(selectProfileRole);

  const isParaplanner = RoleEnum.Paraplanner === profileRole;

  return (
    <GeneralLayout isOneScreenOnly={true} contentClassName="!p-4">
      <div
        className={cn(
          "flex flex-1 overflow-auto gap-6 w-full max-w-6xl mx-auto rounded-[0.5rem] border bg-background shadow",
          isParaplanner && "max-w-full"
        )}
      >
        <MailboxManager navCollapsedSize={4} type={type} />
      </div>
    </GeneralLayout >
  );
};
