import styles from './ThreadsListHeader.module.scss';
import { useSelector } from 'react-redux';
import { Brain, BrainAvatar, EnvelopePlus, Refresh } from "icons";
import { getArchivedThreadsList, getDraftsList, getThreadsList, mailboxActions, selectCreatingDraft, selectThreadsListIsLoading } from 'store/mailbox';
import { selectActiveMailboxConnectedEmail } from 'store/users';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { GENERAL_CONVERSATION_ID, REPLY_TYPE, RoleEnum, THREAD_LIST_LIMIT, TOOLTIP_ID } from 'const';
import { buildInitialDraft } from 'utils/mailbox';
import { Link, useLocation } from 'react-router-dom';
import { getMailboxMenu } from 'configurations';
import React from 'react';
import classnames from 'classnames';
import { ROUTES } from 'routing/constants';
import { selectProfileRole } from 'store/profile';
import { getConversationId, selectIntercomThreadConversationPairs } from 'store/chat';
import { selectGeneralConversationRecordHasUnread } from 'store/notifications';


export const ThreadsListHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;

  const threadsListIsLoading = useSelector(selectThreadsListIsLoading);
  const isCreatingDraft = useAppSelector(selectCreatingDraft);
  const connectedEmail = useAppSelector(selectActiveMailboxConnectedEmail);
  const profileRole = useAppSelector(selectProfileRole); 
  const intercomConversationIds = useAppSelector(selectIntercomThreadConversationPairs);
  const hasGeneralChatUnreadMsgs = useAppSelector(selectGeneralConversationRecordHasUnread);

  const isAdvisor = RoleEnum.Advisor === profileRole;

  const handleRefresh = async () => {
    const payload = { limit: THREAD_LIST_LIMIT, offset: 0 };
    const activeMenuItem = menuItems.find(item => item.isActive)

    await dispatch(mailboxActions.resetThreadsAndSelectedThread());

    if (activeMenuItem?.to === ROUTES.PARAPLANNER_MAILBOX_INBOX || isAdvisor) {
      await dispatch(mailboxActions.setIsRefreshingMailbox(true));
      await dispatch(getThreadsList(payload));
    }
    if (activeMenuItem?.to === ROUTES.PARAPLANNER_MAILBOX_DRAFTS) {
      await dispatch(mailboxActions.setIsRefreshingMailbox(true));
      await dispatch(getDraftsList(payload));
    }
    if (activeMenuItem?.to === ROUTES.PARAPLANNER_MAILBOX_ARCHIVED) {
      await dispatch(mailboxActions.setIsRefreshingMailbox(true));
      await dispatch(getArchivedThreadsList(payload));
    }
  }

  const handleCreateDraft = async () => {
    const draftEmail = buildInitialDraft({ type: REPLY_TYPE.REPLY_TO_ALL, connectedEmail });
    await dispatch(mailboxActions.createDraft({ draft: draftEmail }));
    handleRefresh();
  }

  const menuItems = getMailboxMenu({ pathname, profileRole });

  const onOpenGeneralConversation = async () => {
    dispatch(mailboxActions.updateSelectedThread(null));
    await dispatch(getConversationId({ threadId: GENERAL_CONVERSATION_ID, intercomConversationIds, forceIsOpen: true }));
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Menu}>
        {menuItems?.map(item => (
          item?.to ? <Link key={item.label} className={classnames(styles.Item, { [styles['Item--active']]: item.isActive })} to={item.to}>
            {item.label}
          </Link> : <React.Fragment key={item.label}></React.Fragment>
        ))}
      </div>

      <div className={styles.Actions}>
        {isAdvisor && (
          <div data-tooltip-id={TOOLTIP_ID} data-tooltip-content={hasGeneralChatUnreadMsgs ? t('common.chatWithUsUnread') : t('common.chatWithUs')} data-tooltip-place='left'>
            <BrainAvatar
              size={26}
              hasHover={false}
              className={classnames(styles.BrainIcon, { [styles.BrainIconHasUnread]: hasGeneralChatUnreadMsgs })}
              onClick={() => onOpenGeneralConversation()} />
          </div>
        )}
        <div data-tooltip-id={TOOLTIP_ID} data-tooltip-content={t('common.refresh')} data-tooltip-place='right'>
          <Refresh size={16} hasHover={false} className={styles.ActionIcon} spin={threadsListIsLoading} onClick={() => handleRefresh()} />
        </div>
        {!isAdvisor && (
          <div data-tooltip-id={TOOLTIP_ID} data-tooltip-content={t('common.compose')} data-tooltip-place='right'>
            <EnvelopePlus size={26} hasHover={false} className={styles.ActionIcon} onClick={() => handleCreateDraft()} isLoading={isCreatingDraft} />
          </div>
        )}
      </div>
    </div>
  );
}


