import { useState, ChangeEvent, useRef } from 'react';
import { Loader2, Paperclip, Trash2, X } from 'lucide-react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { IDraftComposer, IEmail, IEmailRecipient, IUploadedFile, mailboxActions, selectDeletingDraft, selectDeletingFiles, selectDownloadingFile, selectUpdatingDraft, selectUploadingFiles } from 'store/mailbox';
import { convertFileSize } from 'utils/mailbox';
import { Button } from 'components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { EmailTypingWidget } from './EmailTypingWidget';
import { EmailHeaderLineLayout } from './EmailHeaderLineLayout';
import { QuillEditor } from './QuillEditor';
import { Input } from 'components/ui/input';

interface IComposeEmailProps {
  draftEmail: IEmail,
}

export const ComposeEmail = ({
  draftEmail,
}: IComposeEmailProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [localDraft, setLocalDraft] = useState<IDraftComposer>({ ...draftEmail, replyToMessageId: draftEmail.reply_to_message_id })
  const [downloadedFileId, setDownloadedFileId] = useState<string>('')

  const updatingDraft = useAppSelector(selectUpdatingDraft);
  const deletingDraft = useAppSelector(selectDeletingDraft);
  const deletingFiles = useAppSelector(selectDeletingFiles);
  const uploadingFiles = useAppSelector(selectUploadingFiles);
  const downloadingFile = useAppSelector(selectDownloadingFile);

  const isLoading = updatingDraft || deletingDraft || deletingFiles || uploadingFiles;

  const syncLocalDraft = (updateObject: Partial<IDraftComposer>) => {
    const payload = { ...localDraft, ...updateObject }

    if (updateObject.hasOwnProperty('files')) {
      payload.fileIdsToAttach = updateObject?.files?.map(file => file.id)
    }

    if (updateObject.hasOwnProperty('cc')) {
      payload.carbonCopy = updateObject?.cc
    }

    setLocalDraft(payload);
  }


  const handleUpdateDraft = async () => {
    if (!localDraft) return;

    const updateDraftResponse = await dispatch(mailboxActions.updateDraft({ draft: localDraft }));
    const updatedDraft = updateDraftResponse.payload as IEmail;

    syncLocalDraft({ ...updatedDraft })
  };

  const handleDeleteDraft = () => {
    dispatch(mailboxActions.deleteDraft({ id: draftEmail?.id || '' }));
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (!files) return;

    const formData = new FormData();
    const filesArray = Array.from(files);
    for (const file of filesArray) {
      formData.append('files', file);
    }

    try {
      const uploadResponse = await dispatch(mailboxActions.uploadFiles(formData));

      syncLocalDraft({ files: [...localDraft.files, ...(uploadResponse.payload as IUploadedFile[])] })

      if (fileInputRef.current) { fileInputRef.current.value = ''; }
    } catch (error) { }
  };


  const handleDeleteFile = async (file: IUploadedFile) => {
    try {
      setDownloadedFileId(file.id);
      const response = await dispatch(mailboxActions.deleteFile({ fileId: file.id, messageId: localDraft?.id }));

      if (mailboxActions.deleteFile.fulfilled.match(response)) {
        syncLocalDraft({ files: [...localDraft.files?.filter(item => item.id !== file.id)] })
      }
    } catch (error) { }
  }


  const downloadAttachment = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, file: IUploadedFile) => {
    event.stopPropagation();
    setDownloadedFileId(file.id);
    dispatch(mailboxActions.getEmailAttachment({ file }))
  };


  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <EmailTypingWidget
          label={t('mailbox.to') as string}
          recipients={localDraft.to || []}
          updateRecipients={(recipients: IEmailRecipient[]) => {
            syncLocalDraft({ to: recipients })
          }}
        />

        {localDraft?.cc?.length > -1 && <EmailTypingWidget
          label={t('mailbox.cc') as string}
          recipients={localDraft.cc || []}
          updateRecipients={(recipients: IEmailRecipient[]) => syncLocalDraft({ cc: recipients })}
        />}

        <EmailHeaderLineLayout label={t('mailbox.subj') as string} content={
          <Input
            className="h-auto rounded-md w-full text-xs"
            aria-label={t('mailbox.subj') as string}
            placeholder={t('mailbox.subject') as string}
            value={localDraft.subject}
            onChange={
              (e) => syncLocalDraft({ subject: e.target.value })
            }
          />
        } />
      </div>

      <QuillEditor
        value={localDraft.body || ''}
        onChange={value => syncLocalDraft({ body: value })}
      />

      {localDraft?.files?.length > 0 && (
        <div>
          <div className="text-xs text-muted-foreground mb-2">{t('mailbox.attachments')}</div>

          <div className="flex flex-col gap-1">
            {localDraft?.files?.map((file, index) => {
              const isProcessing = downloadingFile && file.id === downloadedFileId;
              const isDeleting = deletingFiles && file.id === downloadedFileId;
              return (
                <div key={index} className="flex items-center justify-between max-w-[450px] bg-muted py-1 px-2 rounded-md">
                  <div
                    className="text-xs text-blue-600 cursor-pointer hover:underline"
                    onClick={(event) => downloadAttachment(event, file)}
                  >
                    {file?.filename} ({convertFileSize(file?.size)})
                  </div>

                  {isProcessing || isDeleting ? (
                    <Button variant="ghost" size="icon">
                      <Loader2 className='w-4 h-4 animate-spin' />
                    </Button>
                  ) : (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button variant="ghost" size="icon" onClick={() => handleDeleteFile(file)}>
                          <X className='w-4 h-4' />
                          <span className="sr-only">{t('mailbox.removeFile')}</span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {t('mailbox.removeFile')}
                      </TooltipContent>
                    </Tooltip>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}

      <div className="flex items-center gap-2">
        <Button
          loading={isLoading}
          disabled={(!localDraft?.to?.length && !localDraft?.body)}
          onClick={handleUpdateDraft}
        >
          {t('mailbox.saveDraft')}
        </Button>

        <label>
          <input
            ref={fileInputRef}
            type="file"
            disabled={uploadingFiles}
            className="hidden"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleFileUpload(e.target.files);
            }}
            multiple
          />

          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" size="icon" onClick={() => fileInputRef.current?.click()}>
                <Paperclip className="h-4 w-4" />
                <span className="sr-only">{t('mailbox.attachFiles')}</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              {t('mailbox.attachFiles')}
            </TooltipContent>
          </Tooltip>

        </label>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" onClick={() => handleDeleteDraft()}>
              <Trash2 className="h-4 w-4" />
              <span className="sr-only">{t('mailbox.deleteDraft')}</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {t('mailbox.deleteDraft')}
          </TooltipContent>
        </Tooltip>
      </div>
    </div >
  );
}
