import { cn } from "lib/utils"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, } from "components/ui/select"
import { useAppDispatch, useAppSelector } from "hooks"
import { selectActiveMailbox, selectActiveMailboxConnectedEmail, selectAdvisorMailboxes, updateActiveAdvisorMailbox } from "store/users"
import { Gmail } from "icons/Gmail"

interface AccountSwitcherProps {
  isCollapsed: boolean
}

export const AccountSwitcher = ({ isCollapsed }: AccountSwitcherProps) => {
  const dispatch = useAppDispatch();

  const advisorMailboxes = useAppSelector(selectAdvisorMailboxes);
  const activeMailbox = useAppSelector(selectActiveMailbox);
  const activeMailboxEmail = useAppSelector(selectActiveMailboxConnectedEmail);

  const onChangeMailbox = (authId: string) => {
    const selectedMailbox = advisorMailboxes.find(mailbox => mailbox?.connected_emails?.[0]?.authId === authId)
    dispatch(updateActiveAdvisorMailbox({ payload: selectedMailbox }))
  }

  return (
    <Select value={activeMailboxEmail?.authId} onValueChange={value => onChangeMailbox(value)}>
      <SelectTrigger
        className={cn(
          "flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0",
          isCollapsed &&
          "flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden"
        )}
        aria-label="Select account"
      >
        <SelectValue placeholder="Select an account">
          <Gmail />
          <span className={cn("ml-2", isCollapsed && "hidden")}>
            {activeMailbox?.firstName}
            &nbsp;
            {activeMailbox?.lastName}
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {advisorMailboxes
          ?.map((mailbox) => {
            const email = mailbox?.connected_emails?.[0]?.email;
            const authId = mailbox?.connected_emails?.[0]?.authId;
            return (
              <SelectItem key={authId} value={authId}>
                <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
                  <Gmail />
                  {email || `${mailbox?.firstName} ${mailbox?.lastName}`}
                </div>
              </SelectItem>
            )
          })}
      </SelectContent>
    </Select>
  )
}
