import { useTranslation } from 'hooks';
import { ChangeEvent } from 'react';
import { Search, X } from "lucide-react"
import { Input } from "components/ui/input"
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip"
import { cn } from "lib/utils";

interface IProps {
  value: string;
  setValue: (value: string) => void
  placeholder?: string
  clearIconTooltip?: string
  onChange?: (value: string) => void
  onEnterPressed?: () => void
  onClearSearch?: () => void
  containerClassName?: string
}

export function SearchBox({ value, setValue, placeholder, clearIconTooltip, onChange, onEnterPressed, onClearSearch, containerClassName }: IProps) {
  const { t } = useTranslation();

  const handleSearch = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onEnterPressed && onEnterPressed()
    }

    if (e.key === 'Escape') {
      handleClearSearch()
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value)
  }

  const handleClearSearch = () => {
    setValue('');
    onClearSearch && onClearSearch()
  }

  return (
    <div className={cn("bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60", containerClassName)}>
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder={placeholder || t('general.search') as string}
          className="px-8"
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleSearch}
        />
        {value && (
          <Tooltip >
            <TooltipTrigger className="absolute right-2 top-2.5">
              <X
                size={14}
                className="h-4 w-4 text-muted-foreground cursor-pointer"
                onClick={handleClearSearch}
              />
            </TooltipTrigger>
            <TooltipContent className="-mb-1 -ml-10">
              {clearIconTooltip || t('common.clearSearch')}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
