import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { CirclePlus } from 'lucide-react';
import { toast } from 'react-toastify';
import { cn } from 'lib/utils';
import { Card, CardHeader } from 'components/ui/card';
import { CardTitle } from 'components/ui/card';
import { CreateEditHeadsUpCardModal } from 'components/common/app/_general/CreateEditHeadsUpCardModal';
import { Loading } from 'components/layout-atoms/Loading';
import { ConfirmActionDialog } from 'components/common/_atoms/ConfirmActionDialog';
import { Fieldset } from 'components/ui/fieldset';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { ItemSelectedIcon } from 'components/common/_atoms/ItemSelectedIcon';
import { SearchInputDeprecated } from 'components/common/_atoms/SearchInputDeprecated';
import { IHeadsUpCard, deleteCard, getCards, selectActiveCard, selectHeadsUpCards, selectIsDeletingCard, selectIsGettingCards, setActiveCard } from 'store/heads-up-cards';
import { MIXPANEL } from 'const';
import { DetailsLine } from 'components/common/_atoms/DetailsLine';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';


export const HeadsUpCardsManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const [isCreateCardModalVisible, setIsCreateCardModalVisible] = useState(false)
  const [isEditCardModalVisible, setIsEditCardModalVisible] = useState(false)
  const [isDeleteCardDialogVisible, setIsDeleteCardDialogVisible] = useState(false)

  const [displayList, setDisplayList] = useState<IHeadsUpCard[]>([])
  const [searchValue, setSearchValue] = useState('')

  const headsUpCardsList = useSelector(selectHeadsUpCards);
  const activeCard = useSelector(selectActiveCard);

  const isGettingCards = useSelector(selectIsGettingCards);
  const isDeletingCard = useSelector(selectIsDeletingCard);

  useEffect(() => {
    dispatch(getCards())
  }, [dispatch])


  useEffect(() => {
    if (!searchValue) {
      setDisplayList(headsUpCardsList)
    } else {
      const lowerCaseValue = searchValue?.toLowerCase();

      setDisplayList(headsUpCardsList.filter(item => {
        return [item?.content, item?.title, item?.content, item?.longContent]?.find(item => item?.toLowerCase().includes(lowerCaseValue))
      }))
    }
  }, [headsUpCardsList, searchValue]);

  const handleDeleteCard = async () => {
    if (activeCard) {
      const response = await dispatch(deleteCard(activeCard));

      // Call with success
      if (!response.hasOwnProperty('error')) {
        setIsDeleteCardDialogVisible(false);
        toast(t('component.HeadsUpCardsManagement.deleteAction.success'))
        trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CARDS.DELETE_CARD })
      }
    }
  }


  return (
    <>
      <div className="grid grid-cols-[1fr_2fr] w-full h-full overflow-auto gap-6">
        <Fieldset
          className='basis-1/4'
          legend={t('component.HeadsUpCardsManagement.cards.header')}
          fieldsetActions={[
            {
              onClick: () => setIsCreateCardModalVisible(true),
              tooltip: t('component.HeadsUpCardsManagement.cards.tooltip'),
              content: <CirclePlus className='size-4' />
            }
          ]}
        >
          <SearchInputDeprecated
            onClearSearch={() => setSearchValue('')}
            onChange={(value) => setSearchValue(value)}
            containerClassName="p-0"
            inputPlaceholder={t('component.HeadsUpCardsManagement.cards.searchPlaceholder') as string}
          />

          <div className="grow overflow-auto">
            <div className='flex flex-col gap-3'>
              {
                !isGettingCards ?
                  displayList?.map(card => (
                    <Card
                      key={card.id}
                      variant="item"
                      className={cn('cursor-pointer', { 'border-primary': card?.id === activeCard?.id })}
                      onClick={() => dispatch(setActiveCard(card))}
                    >
                      <CardHeader className="p-3">
                        <CardTitle className="text-sm">{card?.title}</CardTitle>
                      </CardHeader>
                      {card?.id === activeCard?.id && (
                        <ItemSelectedIcon />
                      )}
                    </Card>
                  ))
                  : (
                    <Loading variant="item" />
                  )
              }
              {headsUpCardsList?.length === 0 && (
                <InfoCard
                  variant="item"
                  title=''
                  description={t('component.HeadsUpCardsManagement.info.noData') as string}
                />
              )}
              {headsUpCardsList?.length > 0 && displayList?.length === 0 && (
                <InfoCard
                  variant="item"
                  title=''
                  description={t('component.HeadsUpCardsManagement.info.noFilterData') as string}
                />
              )}
            </div>
          </div>
        </Fieldset>

        <div>
          {
            activeCard && (
              <Fieldset
                className='grow-0 overflow-visible'
                legend={t('component.HeadsUpCardsManagement.details.header')}
              >
                <div className='flex flex-col gap-4'>
                  <div className="grid gap-3">
                    <DetailsLine displayOnSeparateLines={true} label={t('component.CreateEditHeadsUpCardModal.cardTitle')} value={activeCard?.title} />
                    <DetailsLine displayOnSeparateLines={true} label={t('component.CreateEditHeadsUpCardModal.content')} value={activeCard?.content} />
                    <DetailsLine displayOnSeparateLines={true} label={t('component.CreateEditHeadsUpCardModal.longContent')} value={activeCard?.longContent} />
                    <DetailsLine displayOnSeparateLines={true} label={t('component.CreateEditHeadsUpCardModal.context')} value={activeCard?.context} />
                  </div>
                  <Separator />
                  <div className="flex gap-3 justify-end">
                    <Button variant="destructive" onClick={() => setIsDeleteCardDialogVisible(true)}>
                      {t('component.HeadsUpCardsManagement.deleteAction.tooltip')}
                    </Button>
                    <Button onClick={() => {
                      setIsEditCardModalVisible(true)
                    }}>
                      {t('component.HeadsUpCardsManagement.editBtn')}
                    </Button>
                  </div>
                </div>
              </Fieldset>
            )
          }
        </div>
      </div>

      <CreateEditHeadsUpCardModal isVisible={isCreateCardModalVisible} setIsVisible={setIsCreateCardModalVisible} />
      <CreateEditHeadsUpCardModal isVisible={isEditCardModalVisible} setIsVisible={setIsEditCardModalVisible} card={activeCard} />

      <ConfirmActionDialog
        onOk={handleDeleteCard}
        isVisible={isDeleteCardDialogVisible}
        setIsVisible={setIsDeleteCardDialogVisible}
        isProcessing={isDeletingCard}
        title={t('component.HeadsUpCardsManagement.deleteAction.title')}
        description={t('component.HeadsUpCardsManagement.deleteAction.description')}
      />
    </ >
  );
};