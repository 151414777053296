import { useState } from "react"
import { TooltipProvider } from "components/ui/tooltip"
import { cn } from "lib/utils"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "components/ui/resizable"
import { ThreadDetailsPanel } from "./panel-details/ThreadDetailsPanel"
import { useAppSelector } from "hooks"
import { MAILBOX_TYPE, RoleEnum } from "const"
import { selectProfileRole } from "store/profile"
import { ThreadsListPanel } from "./panel-list/ThreadsListPanel"
import { NavigationPanel } from "./panel-navigation/NavigationPanel"

interface MailProps {
  defaultLayout?: number[] | undefined
  defaultCollapsed?: boolean
  navCollapsedSize: number
  type: MAILBOX_TYPE
}

export function MailboxManager({
  defaultLayout = [20, 32, 48],
  defaultCollapsed = false,
  navCollapsedSize,
  type
}: MailProps) {

  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  const profileRole = useAppSelector(selectProfileRole);
  const isAdvisor = RoleEnum.Advisor === profileRole;

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes
          )}`
        }}
        className="h-full items-stretch"
      >
        {!isAdvisor && (
          <>
            <ResizablePanel
              defaultSize={defaultLayout[0]}
              collapsedSize={navCollapsedSize}
              collapsible={true}
              minSize={15}
              maxSize={20}
              onCollapse={() => {
                setIsCollapsed(true)
                document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(true)}`
              }}
              onExpand={() => {
                setIsCollapsed(false)
                document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(false)}`
              }}
              className={cn(isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out")}
            >
              <NavigationPanel isCollapsed={isCollapsed} />
            </ResizablePanel>
            <ResizableHandle withHandle />
          </>
        )}


        <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
          <ThreadsListPanel type={type} />
        </ResizablePanel>

        <ResizableHandle withHandle />

        <ResizablePanel defaultSize={defaultLayout[2]}>
          <ThreadDetailsPanel />
        </ResizablePanel>
      </ResizablePanelGroup>
    </TooltipProvider>
  )
}
