import { useAppSelector } from 'hooks';
import { selectSelectedThread, selectLoadingGetThreadDetails, selectGettingDraft } from 'store/mailbox';
import { Loading } from 'components/layout-atoms/Loading';
import { ComposeEmail } from '../_atoms/ComposeEmail';

export const ThreadDraftComposer = () => {
  const activeThread = useAppSelector(selectSelectedThread);

  const loadingGetThreadDetails = useAppSelector(selectLoadingGetThreadDetails);
  const gettingDraft = useAppSelector(selectGettingDraft);

  const loneDraft = activeThread?.drafts[activeThread?.drafts?.length - 1]

  if (!loneDraft) return (<></>)

  if (loadingGetThreadDetails || gettingDraft) {
    return <Loading variant="centered" className='mt-4' />
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <ComposeEmail draftEmail={loneDraft} />
    </div >
  );
}
