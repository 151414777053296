import { createElement } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from 'routing/constants';
import { RequireAuth } from 'routing/RequireAuth';
import { RequireRole } from './RequireAdminRole';
import { AppGateway } from 'routing/AppGateway';
import { AdvisorMailboxPage, PortfolioComparisonPage, PortfolioAnalysisPage, PlanningSubmissionPage, AutomationPage, SettingsPage as OldSettingsPage } from 'pages';
import { ParaplannerMailbox } from 'pages/ParaplannerMailboxPage';
import { MAILBOX_TYPE, RoleEnum } from 'const';
import { ThreadsList } from 'components_old/features/Mailbox';
import { ISDEIEWizard, ISDEIEHCWizard, ISDataEntryWizard, PortfolioAnalysisWizard } from 'components_old';
import { RequireAuthMuninAI } from './RequireAuthMuninAI';
import { ConfigureCopilotPage } from 'components/pages/app/copilot/configure/ConfigureCopilotPage';
import { AssistedCopilotMeetingPage } from 'components/pages/app/copilot/meeting/AssistedCopilotMeetingPage';
import { CopilotMeetingSummaryPage } from 'components/pages/app/copilot/summary/CopilotMeetingSummaryPage';
import { ClientsPage } from 'components/pages/app/clients/ClientsPage';
import { MeetingDataRecordPage } from 'components/pages/app/clients/meeting/MeetingDataRecordPage';
import { LoginPage } from 'components/pages/login/LoginPage';
import { SignUpPage } from 'components/pages/signup/SignUpPage';
import { ParaplannerAIPage } from 'components/pages/app/paraplannerai/ParaplannerAIPage';
import { SettingsPage } from 'components/pages/app/settings/SettingsPage';
import { ShareFilesViaUpload } from 'components/common/app/paraplannerai/upload-file/ShareFilesViaUpload';
import { ShareFilesViaLink } from 'components/common/app/paraplannerai/share-link/ShareFilesViaLink';
import { AdminSettingsPage } from 'components/pages/admin/settings/AdminSettingsPage';
import { UserManagement } from 'components/common/admin/user-management/UserManagement';
import { ShareCredentials } from 'components/common/app/settings/credentials/ShareCredentials';
import { MailboxPage } from 'components/pages/app/mailbox/MailboxPage';
import { TemplatesManagement } from 'components/common/app/settings/templates/TemplatesManagement';
import { ComplianceSettings } from 'components/common/app/settings/compliance/ComplianceSettings';
import { StatementExtraction } from 'components/common/app/paraplannerai/statement-extraction/StatementExtraction';
import { OrganizationManagement } from 'components/common/admin/organization-management/OrganizationManagement';
import { InPersonMeetingPage } from 'components/pages/app/copilot/inPerson/InPersonMeetingPage';
import { HeadsUpCardsManagement } from 'components/common/app/settings/cards/HeadsUpCardsManagement';
import Integrations from '../components/common/app/settings/integrations/Integrations';
import { ManageConnections } from 'components/common/app/settings/connections/ManageConnections';
import { TemplateManagementTypeEnum } from 'store/templates-management';
import { AdminTemplatesManagement } from 'components/common/admin/templates-management/AdminTemplatesManagement';
import { DataPointsManagement } from 'components/common/app/settings/data-points/DataPointsManagement';
import { MailboxesPage } from 'components/pages/paraplanner/mailboxes/MailboxesPage';


export const router = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <AppGateway />,
      },
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
      // {
      //   path: ROUTES.SIGN_UP,
      //   element: <SignUpPage />,
      // },
      {
        path: ROUTES.APP.DEFAULT,
        element: createElement(RequireAuthMuninAI, { requiredRole: RoleEnum.Advisor }),
        children: [
          { index: true, element: <Navigate to={ROUTES.APP.COPILOT.CONFIGURE} /> },
          {
            path: ROUTES.APP.MAILBOX.DEFAULT,
            children: [
              { index: true, element: <MailboxPage /> },
            ]
          },
          {
            path: ROUTES.APP.CLIENTS.DEFAULT,
            children: [
              { index: true, element: <ClientsPage /> },
              { path: ROUTES.APP.CLIENTS.MEETING, element: <MeetingDataRecordPage /> },
            ]
          },
          {
            path: ROUTES.APP.COPILOT.DEFAULT,
            children: [
              { index: true, element: <Navigate to={ROUTES.APP.COPILOT.CONFIGURE} /> },
              { path: ROUTES.APP.COPILOT.CONFIGURE, element: <ConfigureCopilotPage /> },
              { path: ROUTES.APP.COPILOT.MEETING, element: <AssistedCopilotMeetingPage /> },
              { path: ROUTES.APP.COPILOT.IN_PERSON, element: <InPersonMeetingPage /> },
              { path: ROUTES.APP.COPILOT.SUMMARY, element: <CopilotMeetingSummaryPage /> },
            ]
          },
          {
            path: ROUTES.APP.PARAPLANNER.DEFAULT,
            element: <ParaplannerAIPage />,
            children: [
              { index: true, element: <Navigate to={ROUTES.APP.PARAPLANNER.UPLOAD_FILE} /> },
              { path: ROUTES.APP.PARAPLANNER.UPLOAD_FILE, element: <ShareFilesViaUpload /> },
              { path: ROUTES.APP.PARAPLANNER.SHARE_LINK, element: <ShareFilesViaLink /> },
              { path: ROUTES.APP.PARAPLANNER.STATEMENT_EXTRACTION, element: <StatementExtraction /> },
            ]
          },
          {
            path: ROUTES.APP.SETTINGS.DEFAULT,
            element: <SettingsPage />,
            children: [
              { index: true, element: <Navigate to={ROUTES.APP.SETTINGS.MY_TEMPLATES} /> },
              { path: ROUTES.APP.SETTINGS.MY_TEMPLATES, element: <TemplatesManagement key="personal" type={TemplateManagementTypeEnum.PERSONAL} /> },
              { path: ROUTES.APP.SETTINGS.ORG_TEMPLATES, element: <TemplatesManagement key="org" type={TemplateManagementTypeEnum.ORGANIZATION} /> },
              { path: ROUTES.APP.SETTINGS.DATA_POINTS, element: <DataPointsManagement /> },
              { path: ROUTES.APP.SETTINGS.CREDENTIALS, element: <ShareCredentials /> },
              { path: ROUTES.APP.SETTINGS.CONNECTIONS, element: <ManageConnections /> },
              { path: ROUTES.APP.SETTINGS.INTEGRATIONS, element: <Integrations /> },
              { path: ROUTES.APP.SETTINGS.CARDS, element: <HeadsUpCardsManagement /> },
              { path: ROUTES.APP.SETTINGS.COMPLIANCE, element: <ComplianceSettings /> },
            ]
          },
        ]
      },
      {
        path: ROUTES.ADMIN.DEFAULT,
        element: createElement(RequireAuthMuninAI, { requiredRole: RoleEnum.Admin }),
        children: [
          { index: true, element: <Navigate to={ROUTES.ADMIN.SETTINGS.DEFAULT} /> },
          {
            path: ROUTES.ADMIN.SETTINGS.DEFAULT,
            element: <AdminSettingsPage />,
            children: [
              { index: true, element: <Navigate to={ROUTES.ADMIN.SETTINGS.USER_MANAGEMENT} /> },
              { path: ROUTES.ADMIN.SETTINGS.USER_MANAGEMENT, element: <UserManagement /> },
              { path: ROUTES.ADMIN.SETTINGS.ORGANIZATION_MANAGEMENT, element: <OrganizationManagement /> },
              { path: ROUTES.ADMIN.SETTINGS.TEMPLATES_MANAGEMENT, element: <AdminTemplatesManagement /> },
            ]
          },
        ]
      },
      {
        path: ROUTES.PARAPLANNER.DEFAULT,
        element: createElement(RequireAuthMuninAI, { requiredRole: RoleEnum.Paraplanner }),
        children: [
          { index: true, element: <Navigate to={ROUTES.PARAPLANNER.MAILBOX.DEFAULT} /> },
          {
            path: ROUTES.PARAPLANNER.MAILBOX.DEFAULT,
            children: [
              { index: true, element: <Navigate to={ROUTES.PARAPLANNER.MAILBOX.INBOX} /> },
              { path: ROUTES.PARAPLANNER.MAILBOX.INBOX, element: <MailboxesPage type={MAILBOX_TYPE.INBOX} /> },
              { path: ROUTES.PARAPLANNER.MAILBOX.DRAFTS, element: <MailboxesPage type={MAILBOX_TYPE.DRAFTS} /> },
              { path: ROUTES.PARAPLANNER.MAILBOX.ARCHIVED, element: <MailboxesPage type={MAILBOX_TYPE.ARCHIVED} /> },
            ]
          },
        ]
      },
      {
        path: ROUTES.APP_OLD,
        Component: RequireAuth,
        children: [
          { index: true, element: <AppGateway /> },
          {
            path: ROUTES.PARAPLANNER_OLD,
            element: createElement(RequireRole, { requiredRole: RoleEnum.Paraplanner }),
            children: [
              {
                index: true,
                element: <ParaplannerMailbox />,
              },
              {
                path: ROUTES.PARAPLANNER_MAILBOX,
                element: <ParaplannerMailbox />,
                children: [
                  {
                    index: true,
                    element: <ThreadsList type={MAILBOX_TYPE.INBOX} />,
                  },
                  {
                    path: ROUTES.PARAPLANNER_MAILBOX_INBOX,
                    element: <ThreadsList type={MAILBOX_TYPE.INBOX} />,
                  },
                  {
                    path: ROUTES.PARAPLANNER_MAILBOX_DRAFTS,
                    element: <ThreadsList type={MAILBOX_TYPE.DRAFTS} />,
                  },
                  {
                    path: ROUTES.PARAPLANNER_MAILBOX_ARCHIVED,
                    element: <ThreadsList type={MAILBOX_TYPE.ARCHIVED} />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTES.ADVISOR,
            element: createElement(RequireRole, { requiredRole: RoleEnum.Advisor }),
            children: [
              {
                index: true,
                element: <PortfolioComparisonPage />,
              },
              {
                path: ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
                element: <PortfolioComparisonPage />,
              },
              {
                path: ROUTES.ADVISOR_PORTFOLIO_ANALYSIS,
                element: <PortfolioAnalysisPage />,
              },
              {
                path: ROUTES.PLANNING_SUBMISSION_FORM,
                element: <PlanningSubmissionPage />,
              },
              {
                path: ROUTES.ADVISOR_AUTOMATIONS,
                children: [
                  {
                    index: true,
                    element: <AutomationPage />,
                  },
                  {
                    path: ROUTES.ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL,
                    element: <ISDEIEWizard />,
                  },
                  {
                    path: ROUTES.ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL_HUMAN_CHECK,
                    element: <ISDEIEHCWizard />,
                  },
                  {
                    path: ROUTES.ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_DATA_ENTRY,
                    element: <ISDataEntryWizard />,
                  },
                  {
                    path: ROUTES.ADVISOR_AUTOMATIONS__PORTFOLIO_ANALYSIS,
                    element: <PortfolioAnalysisWizard />,
                  },
                ]
              },
              {
                path: ROUTES.ADVISOR_SETTINGS,
                children: [
                  {
                    index: true,
                    element: <OldSettingsPage />,
                  },
                  {
                    path: ROUTES.ADVISOR_SETTINGS_MODELS,
                    element: <OldSettingsPage />,
                  },
                  {
                    path: ROUTES.ADVISOR_SETTINGS_CONNECT,
                    element: <OldSettingsPage />,
                  },
                ]
              },
              {
                path: ROUTES.ADVISOR_MAILBOX,
                element: <AdvisorMailboxPage />,
                children: [
                  {
                    index: true,
                    element: <ThreadsList type={MAILBOX_TYPE.INBOX} />,
                  },
                  {
                    path: ROUTES.ADVISOR_MAILBOX_ALL,
                    element: <ThreadsList type={MAILBOX_TYPE.INBOX} />,
                  },
                  {
                    path: ROUTES.ADVISOR_MAILBOX_WITH_CONVERSATION,
                    element: <ThreadsList type={MAILBOX_TYPE.CONVERSATIONS} />,
                  },
                ],
              },
            ],
          },
        ],
      },

    ],
    errorElement: <Navigate to={ROUTES.APP.DEFAULT} replace />,
  },
]);
