import { CircleUser, LogOutIcon, Menu, } from "lucide-react"
import { Button } from "components/ui/button"
import { Sheet, SheetContent, SheetTrigger } from "components/ui/sheet"
import { useAppDispatch, useAppSelector, useDevFlag, useTranslation } from 'hooks';
import { SignOutReason, getUser, getUserRole, logout } from 'store/authentication';
import { RoleEnum } from 'const';
import { i18n } from 'i18n';
import { ROUTES } from 'routing/constants';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu';
import { IMenuItem, MenuItem } from 'components/layout-atoms/MenuItem';
import { getProfileDetails, selectProfileDetails } from "store/profile";
import { cn } from "lib/utils";
import { useEffect } from "react";
import { getAdvisorMailboxes, IAdvisorsMailbox, selectActiveMailbox, selectAdvisorMailboxes, updateActiveAdvisorMailbox } from "store/users";
import { useSearchParams } from "react-router-dom";

export const AppMenu = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const { isDevEnv } = useDevFlag()

  const pathname = window.location.pathname;

  const currentUser = useAppSelector(getUser);
  const userRole = useAppSelector(getUserRole);
  const myProfileData = useAppSelector(selectProfileDetails)

  const advisorMailboxes = useAppSelector(selectAdvisorMailboxes);
  const activeMailbox = useAppSelector(selectActiveMailbox);

  const menuItems = getMenuItems(userRole, isDevEnv, activeMailbox);


  useEffect(() => {
    if (currentUser.id) {
      dispatch(getProfileDetails())
    }
  }, [currentUser?.id, dispatch]);

  /* Mailbox logic */
  // I have the profile, get the mailboxes list
  useEffect(() => {
    if (myProfileData.id && myProfileData.role !== RoleEnum.Admin) {
      dispatch(getAdvisorMailboxes(null));
    }
  }, [dispatch, myProfileData]);

  // After I get the data set the first mailbox as active
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authId = params.get('authId');

    if (advisorMailboxes?.length) {
      if (authId) {
        const mailboxFromParam = authId ? advisorMailboxes?.find(mailbox => mailbox?.connected_emails[0]?.authId === authId) : undefined

        if (authId) {
          params.delete('authId');
          setSearchParams(params);
        }
        dispatch(updateActiveAdvisorMailbox({ payload: mailboxFromParam || advisorMailboxes[0] }))
      } else if (!activeMailbox) {
        dispatch(updateActiveAdvisorMailbox({ payload: advisorMailboxes[0] }))
      }
    }
  }, [dispatch, advisorMailboxes, activeMailbox])

  return (
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-10 z-10 justify-center">
      <div className={cn("flex w-full max-w-6xl justify-between", { "max-w-full": pathname.includes(ROUTES.APP.CLIENTS.DEFAULT) })}>
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6 max-w-6xl">
          {
            menuItems?.map(item => (
              <MenuItem key={item.label} item={item} />
            ))
          }
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              {
                menuItems?.map(item => (
                  <MenuItem key={item.label} item={item} />
                ))
              }
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex items-center gap-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full  focus-visible:ring-0">
                <CircleUser className="size-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end">
              <DropdownMenuLabel>
                {
                  myProfileData?.firstName ? `${myProfileData?.firstName} ${myProfileData?.lastName}` : t('menu.myAccount')
                }
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => dispatch(logout({ reason: SignOutReason.USER_ACTION }))}
                className='gap-2 cursor-pointer'
              >
                <LogOutIcon className='size-4' />
                {i18n.t('general.signOut')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  )
}

export const getMenuItems = (role: RoleEnum | null, isDevEnv?: boolean, activeMailbox?: IAdvisorsMailbox | null): IMenuItem[] => {
  if (!role) return [];

  const menu: IMenuItem[] = [
    {
      label: i18n.t('menu.app.clients'),
      to: ROUTES.APP.CLIENTS.DEFAULT,
      availableFor: [RoleEnum.Advisor],
    },
    {
      label: i18n.t('menu.app.copilot'),
      to: ROUTES.APP.COPILOT.DEFAULT,
      availableFor: [RoleEnum.Advisor],
    },
    {
      label: i18n.t('menu.app.paraplanner'),
      to: ROUTES.APP.PARAPLANNER.DEFAULT,
      availableFor: [RoleEnum.Advisor],
    },
    {
      label: i18n.t('menu.app.settings'),
      to: ROUTES.APP.SETTINGS.DEFAULT,
      availableFor: [RoleEnum.Advisor],
    },
    {
      label: i18n.t('menu.app.mailbox'),
      to: ROUTES.APP.MAILBOX.DEFAULT,
      availableFor: [RoleEnum.Advisor],
      isHidden: !isDevEnv || !activeMailbox

    },
    {
      label: i18n.t('menu.admin.settings'),
      to: ROUTES.ADMIN.SETTINGS.DEFAULT,
      availableFor: [RoleEnum.Admin],
    },
  ];

  return menu.filter(entry => entry.availableFor?.includes(role) && !entry.isHidden);
}


