import { Link, useLocation } from 'react-router-dom';
import { i18n } from 'i18n';
import { cn } from 'lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { RoleEnum } from 'const';
import { Fragment } from 'react';
import { cva, type VariantProps } from "class-variance-authority"


const menuItemVariants = cva(
  "transition-colors block relative w-fit",
  {
    variants: {
      variant: {
        app: "text-muted-foreground hover:text-foreground leading-none",
        side: "text-muted-foreground hover:text-foreground",
      },
    },
    defaultVariants: {
      variant: "app",
    },
  }
)

const activeClasses = {
  app: "text-primary",
  side: "text-primary font-semibold",
};

export interface IMenuItem {
  label: string;
  to: string;
  availableFor?: RoleEnum[];
  isActive?: boolean;
  isComingSoon?: boolean;
  isHidden?: boolean;
}

interface IProps extends VariantProps<typeof menuItemVariants> {
  item: IMenuItem;
}

export const MenuItem = ({ item, variant }: IProps) => {
  const { pathname } = useLocation();

  const isItemActive = (menuItem: IMenuItem) => {
    const hasLinkIsActiveKey = Object.hasOwn(menuItem, 'isActive');
    const defaultIsActiveCheck = pathname.includes(menuItem.to);
    return hasLinkIsActiveKey ? menuItem.isActive : defaultIsActiveCheck;
  }

  return (
    <Fragment>
      {
        !item?.isComingSoon ?
          <Link
            key={item.to}
            to={item.to}
            className={cn(menuItemVariants({ variant }), { [activeClasses[variant || 'app']]: isItemActive(item) })}
          >
            {item.label}
          </Link>
          :
          <span key={item.to} className={cn(menuItemVariants({ variant }))}>
            <Tooltip>
              <TooltipTrigger asChild>
                <span className='opacity-60 cursor-default'>
                  {item.label}
                </span>
              </TooltipTrigger>
              <TooltipContent>
                {i18n.t('general.comingSoon')}
              </TooltipContent>
            </Tooltip>
          </span>
      }
    </Fragment>
  )
}


