import { Fragment } from 'react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { IConnectRedtailPayload, connectRedtail, selectIsConnectingToRedtail } from 'store/redtail';
import { INylasForm } from '../settings/connections/ConnectEmailNylas';
import { NYLAS_SUCCESS_URL } from 'const';
import { generateAuthUrl } from 'store/nylas';
import { createPersonalTemplate, createOrgTemplate } from 'store/templates-management';

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  onCreateCompleted?: (payload: void) => void
}

export interface IConnectNylasPayload {
  email: string;
}


export const ConnectNylasModal = ({ isVisible, setIsVisible, onCreateCompleted }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isProcessing = useAppSelector(selectIsConnectingToRedtail);

  const formSchema = z.object({
    email: z.string().email(t('errors.wrongEmailFormat') as string),
  });

  const form = useForm<Required<INylasForm>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    }
  });

  const handleConnectToNylas = async (values: INylasForm) => {
    const response = await dispatch(generateAuthUrl({
      emailAddress: values.email,
      successUrl: NYLAS_SUCCESS_URL
    }));

    if (generateAuthUrl.fulfilled.match(response)) {
      form.reset();
      setIsVisible(false);
      onCreateCompleted && onCreateCompleted()
    }
  }

  return (
    <Fragment>
      <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{t('component.ConnectRedtailModal.title')}</DialogTitle>
            <DialogDescription>
              {t('component.ConnectRedtailModal.description')}
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleConnectToNylas)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.ConnectEmailNylas.label.email')} *
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button
                  type="submit"
                  loading={isProcessing}
                >
                  {t('component.ConnectRedtailModal.btn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
