import { useState, Fragment } from 'react';
import classnames from 'classnames';
import { selectActiveMailboxConnectedEmail } from 'store/users/selectors';
import { buildInitialDraft, convertFileSize, getMessageMeta } from 'utils/mailbox';
import { REPLY_TYPE, RoleEnum } from 'const';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { selectSelectedThread, selectLoadingThreadMessageId, selectCreatingDraft, mailboxActions, selectThreadsListData, selectExpandedMessageIds, selectLoadingGetThreadDetails, selectDownloadingFile, IUploadedFile, IEmail } from 'store/mailbox';
import { cleanEmailBody, filterRelevantMessageAttachments } from 'utils/mailbox';
import { selectProfileRole } from 'store/profile';
import { EmailInfo } from '../_atoms/EmailInfo';
import { Loading } from 'components/layout-atoms/Loading';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';
import { Ellipsis, Loader2, Paperclip } from 'lucide-react';
import { ComposeEmail } from '../_atoms/ComposeEmail';

export const ThreadDetailsViewer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const threadsList = useAppSelector(selectThreadsListData);
  const activeThread = useAppSelector(selectSelectedThread);
  const messages = activeThread?.messages || [];

  const loadingGetThreadDetails = useAppSelector(selectLoadingGetThreadDetails);
  const loadingMessageId = useAppSelector(selectLoadingThreadMessageId);
  const creatingEmailDraft = useAppSelector(selectCreatingDraft);
  const downloadingFile = useAppSelector(selectDownloadingFile);
  const connectedEmail = useAppSelector(selectActiveMailboxConnectedEmail);
  const expandedMessageIds = useAppSelector(selectExpandedMessageIds);
  const profileRole = useAppSelector(selectProfileRole);

  const [draftTypes, setDraftTypes] = useState<{ [key: string]: keyof typeof REPLY_TYPE }>({});
  const [showCollapsedContentForMsgs, setShowCollapsedContentForMsgs] = useState<string[]>([])
  const [clickedFileId, setClickedFileId] = useState<string>('')

  const handleToggleMessage = async (message: IEmail) => {
    if (messages && messages?.length <= 1) return;

    if (!message.body) {
      dispatch(
        mailboxActions.getMessage({ id: message.id })
      );
    } else {
      if (expandedMessageIds.includes(message.id)) {
        dispatch(mailboxActions.removeIdFromExpandedMessages(message.id));
      } else {
        dispatch(mailboxActions.addIdToExpandedMessages(message.id));
      }
    }
  };

  const handleReplyToAll = (message: IEmail) => {
    const draftEmail = buildInitialDraft({ email: message, type: REPLY_TYPE.REPLY_TO_ALL, connectedEmail });
    setDraftTypes({ ...draftTypes, [message.id]: REPLY_TYPE.REPLY_TO_ALL })
    dispatch(mailboxActions.createDraft({ draft: draftEmail }))
  }

  const downloadAttachment = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, file: IUploadedFile) => {
    event.stopPropagation();
    setClickedFileId(file.id);
    dispatch(
      mailboxActions.getEmailAttachment({ file })
    )
  };

  const toggleCollapsedContent = (messageId: string) => {
    if (showCollapsedContentForMsgs.includes(messageId)) {
      setShowCollapsedContentForMsgs(showCollapsedContentForMsgs?.filter(id => id !== messageId))
    } else {
      setShowCollapsedContentForMsgs([...showCollapsedContentForMsgs, messageId])
    }
  }

  if (loadingGetThreadDetails) {
    return <Loading variant="centered" className='mt-4' />
  }

  return (
    <div>
      {
        threadsList && threadsList?.length > 0 && !activeThread && (
          <InfoCard
            variant="item"
            title={t('mailbox.selectThread')}
            className="flex justify-center border-0"
          />
        )
      }

      {messages?.map((message, index) => {
        const isMessageLoading = message?.id === loadingMessageId;
        const isExpanded = expandedMessageIds && expandedMessageIds?.includes(message.id);

        const draftMessages = activeThread?.drafts?.filter(draft => draft.reply_to_message_id === message.id) || [];
        const draftMessage = draftMessages?.length > 0 ? draftMessages[draftMessages?.length - 1] : null;

        const { hasAttachments, isGmailWithHistory, isOutlookWithHistory } = getMessageMeta(message);
        const hasHistory = isGmailWithHistory || isOutlookWithHistory;

        return (
          <Fragment key={message.id}>
            <div className="flex flex-col gap-4 p-4">
              <div onClick={() => handleToggleMessage(message)} className='cursor-pointer'>
                <EmailInfo message={message} isMessageExpanded={isExpanded} />
              </div>

              {isExpanded && (
                <div>
                  <div
                    className={classnames("text-xs",
                      {
                        'msg__gmail--hide-history': isGmailWithHistory,
                        'msg__outlook--hide-history': isOutlookWithHistory,
                      }
                    )}
                    dangerouslySetInnerHTML={{
                      __html: cleanEmailBody(message.body || ''),
                    }}
                  />

                  {hasHistory && (
                    <Ellipsis
                      className='w-6 h-4 cursor-pointer border border-muted-foreground/20 rounded-sm bg-muted'
                      onClick={() => toggleCollapsedContent(message.id)}
                    />
                  )}

                  {hasHistory && showCollapsedContentForMsgs.includes(message.id) && (
                    <div
                      className={classnames("text-xs",
                        {
                          'msg__gmail--hide-current': isGmailWithHistory,
                          'msg__outlook--hide-current': isOutlookWithHistory,
                        }
                      )}
                      dangerouslySetInnerHTML={{
                        __html: cleanEmailBody(message.body || ''),
                      }}
                    />
                  )}
                </div>
              )}

              {isExpanded && hasAttachments && (
                <div>
                  <div className="text-xs text-muted-foreground mb-1">
                    {t('mailbox.attachments')}
                  </div>
                  <div className='flex flex-col gap-1'>
                    {
                      filterRelevantMessageAttachments(message).map((f: IUploadedFile) => {
                        const isDownloading = downloadingFile && f.id === clickedFileId;

                        return (
                          <div
                            className="flex items-center gap-2 text-xs text-muted-foreground cursor-pointer"
                            key={f.id}
                            onClick={(event) => {
                              if (isDownloading) return;
                              downloadAttachment(event, f)
                            }}
                          >
                            {isDownloading ? (
                              <Loader2 className='w-4 h-4 animate-spin' />
                            ) : (
                              <Paperclip className='w-4 h-4' />
                            )}
                            <span className='hover:underline'>{f.filename} ({convertFileSize(f.size)})</span>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )}

              {isExpanded && draftMessage && (
                <>
                  <Separator />
                  <div className='border rounded-lg p-4'>
                    <ComposeEmail draftEmail={draftMessage} />
                  </div>
                </>
              )}

              {profileRole !== RoleEnum.Advisor && isExpanded && !draftMessage && (
                <div>
                  <Button loading={creatingEmailDraft} onClick={() => handleReplyToAll(message)}                    >
                    {(message.to?.length > 1 || message.cc?.length > 0) ? t('common.replyToAll') : t('common.reply')}
                  </Button>
                </div>
              )}

              {isMessageLoading && (
                <Loading variant="centered" />
              )}
            </div>

            {index !== messages?.length - 1 && (
              <Separator />
            )}
          </Fragment>
        );
      })}
    </div >
  );
}
