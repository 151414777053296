import { ScrollArea } from "components/ui/scroll-area"
import { useAppDispatch, useAppSelector, useIsInViewport, useTranslation } from 'hooks';
import { selectSelectedThread, selectThreadsListHasMoreData, selectThreadsListOffset, selectThreadListLoading, getThreadsList, selectThreadsListData, getArchivedThreadsList, getDraftsList, mailboxActions, getThreadsWithConversationList, searchThreadsList, selectIsRefreshingMailbox, IEmailThread } from 'store/mailbox';
import { useEffect, useRef, useState } from 'react';
import { ACTION } from 'const/actions';
import { MAILBOX_TYPE, THREAD_LIST_LIMIT } from 'const';
import { selectConnectedEmailAuthId } from 'store/users';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { InfoCard } from "components/layout-atoms/InfoCard"
import { Loading } from "components/layout-atoms/Loading"
import { ThreadsListItem } from "./ThreadsListItem"
import { SearchBox } from "components/common/_atoms/SearchBox";
import { Separator } from "components/ui/separator";

interface IProps {
  type?: MAILBOX_TYPE
}

export function ThreadsList({ type = MAILBOX_TYPE.INBOX }: IProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();

  const threadsList = useAppSelector(selectThreadsListData) || [];

  const activeThread = useAppSelector(selectSelectedThread);
  const threadsListOffset = useAppSelector(selectThreadsListOffset);
  const threadsListHasMoreData = useAppSelector(selectThreadsListHasMoreData);
  const threadsListIsLoading = useAppSelector(selectThreadListLoading);
  const connectedEmailAuthId = useAppSelector(selectConnectedEmailAuthId);
  const isRefreshingMailbox = useAppSelector(selectIsRefreshingMailbox);

  const [threadCallType, setThreadCallType] = useState("");
  const [searchBoxValue, setSearchBoxValue] = useState("");

  const loaderRef = useRef(null)
  const isInViewport = useIsInViewport(loaderRef);

  useEffect(() => {
    setThreadCallType(type);
  }, [type])

  useEffect(() => {
    if (isRefreshingMailbox || !([MAILBOX_TYPE.INBOX, MAILBOX_TYPE.SEARCH] as string[]).includes(threadCallType)) {
      setSearchBoxValue('')
    }
  }, [threadCallType, isRefreshingMailbox])


  useEffect(() => {
    const initNewTab = async () => {
      if (!connectedEmailAuthId) return;
      if (threadCallType === MAILBOX_TYPE.SEARCH) return;

      const payload = { limit: THREAD_LIST_LIMIT, offset: 0 };

      await dispatch(mailboxActions.resetThreadsAndSelectedThread());

      if (threadCallType === MAILBOX_TYPE.INBOX) {
        await dispatch(getThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.DRAFTS) {
        await dispatch(getDraftsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.ARCHIVED) {
        await dispatch(getArchivedThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.CONVERSATIONS) {
        await dispatch(getThreadsWithConversationList(payload));
      }
    }

    initNewTab();
  }, [threadCallType, connectedEmailAuthId, dispatch])


  const debouncedGetData = debounce(async () => {
    if (searchBoxValue) return;

    if (isInViewport && !threadsListIsLoading && threadsListOffset && threadsListHasMoreData) {
      const payload = { limit: THREAD_LIST_LIMIT, offset: threadsListOffset };


      if (threadCallType === MAILBOX_TYPE.INBOX) {
        await dispatch(getThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.DRAFTS) {
        await dispatch(getDraftsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.ARCHIVED) {
        await dispatch(getArchivedThreadsList(payload));
      }

    }
  }, 1000);

  useEffect(() => {
    if (!connectedEmailAuthId) return;

    const getData = async () => {
      debouncedGetData();
    };

    getData();
  }, [isInViewport, threadsListOffset, threadsListHasMoreData, threadsListIsLoading, connectedEmailAuthId, dispatch, type, searchBoxValue])

  useEffect(() => {
    return () => {
      debouncedGetData.cancel();
    };
  }, []);


  useEffect(() => {
    if (threadsList?.length && !activeThread) {

      const params = new URLSearchParams(window.location.search);
      const threadId = params.get('threadId');
      const threadFromParam = threadId ? threadsList?.find(thread => thread.thread_id === threadId) : undefined

      if (threadId) {
        params.delete('threadId');
        setSearchParams(params);
      }

      dispatch({ type: ACTION.MAILBOX_MIDDLEWARE.TRIGGER_SET_SELECTED_THREAD, payload: threadFromParam })
    }
  }, [threadsList, activeThread, dispatch])

  const handleThreadSelect = (thread: IEmailThread) => {
    dispatch({ type: ACTION.MAILBOX_MIDDLEWARE.TRIGGER_SET_SELECTED_THREAD, payload: thread })
  };

  const handleEnterPressed = () => {
    if (searchBoxValue) {
      setThreadCallType(MAILBOX_TYPE.SEARCH)

      const payload = { limit: THREAD_LIST_LIMIT, q: searchBoxValue };
      dispatch(searchThreadsList(payload));
    } else {
      setThreadCallType(MAILBOX_TYPE.INBOX)
    }
  }

  const handleClearSearch = async () => {
    setSearchBoxValue('');
    setThreadCallType(MAILBOX_TYPE.INBOX)
  }

  return (
    <div className="h-full flex flex-col gap-4 py-4">
      {type === MAILBOX_TYPE.INBOX && (
        <SearchBox
          value={searchBoxValue}
          setValue={setSearchBoxValue}
          placeholder={t('common.searchInEmails') as string}
          onEnterPressed={handleEnterPressed}
          onClearSearch={handleClearSearch}
          containerClassName="px-4"
        />
      )}

      <ScrollArea className="grow overflow-auto">
        <div className="flex flex-col gap-2 pb-4 px-4">
          {threadsList?.filter(thread => {
            if (!thread.messages?.length && !thread.drafts?.length) return false;
            if (type === MAILBOX_TYPE.INBOX && thread?.labels?.length > 0) return false;
            if (type === MAILBOX_TYPE.ARCHIVED && thread?.labels?.length > 0) return false;

            return true;
          })?.map((thread) => (
            <div key={thread.thread_id} onClick={() => handleThreadSelect(thread)} className="w-full">
              <ThreadsListItem
                thread={thread}
                isActive={activeThread?.thread_id === thread.thread_id}
              />
            </div>
          ))}

          {threadsListIsLoading && <Loading variant="item" className="p-6 w-full" />}

          {!threadsListIsLoading && threadsList.length === 0 && (
            <InfoCard
              variant="item"
              title={t('mailbox.noEmailMessages')}
              className="flex justify-center border-0"
            />
          )}

          {!threadsListHasMoreData && !threadsListIsLoading && (
            <div className="relative mt-6">
              <Separator className="mt-0" />
              <div className="absolute -top-2 left-1/2 -translate-x-1/2 text-xs text-muted-foreground text-center px-2 bg-white">
                {t('mailbox.noMoreEmails')}
              </div>
            </div>
          )}

          <div ref={loaderRef} className="my-6 text-xs">&nbsp;</div>
        </div>
      </ScrollArea>
    </div>
  )
}
