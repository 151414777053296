import { ReactNode } from 'react';

interface IProps {
  label: ReactNode | string;
  content: ReactNode | string,
}

export const EmailHeaderLineLayout = ({
  label,
  content
}: IProps) => {
  return (
    <div className="flex items-baseline">
      <label className="text-xs text-muted-foreground min-w-8">
        {label}
      </label>
      <div className="w-full">
        {content}
      </div>
    </div>
  );
}
