import { useState } from 'react';
import { toast } from 'react-toastify';
import { Input } from 'components/ui/input';
import { IEmailRecipient } from 'store/mailbox';
import { Badge } from 'components/ui/badge';
import { X } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { useTranslation } from 'hooks';

import { EmailHeaderLineLayout } from './EmailHeaderLineLayout';
import { cn } from 'lib/utils';

interface IProps {
  label: string;
  recipients: IEmailRecipient[],
  updateRecipients?: (recipients: IEmailRecipient[]) => void,
}

export const EmailTypingWidget = ({
  label,
  recipients,
  updateRecipients,
}: IProps) => {
  const [emailInput, setEmailInput] = useState<string>('');
  const { t } = useTranslation();

  const isInEditMode = !!updateRecipients;

  const handleAddingRecipient = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && emailInput) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = re.test(emailInput);

      if (!isValid) {
        toast(t('mailbox.invalidEmailAddress') as string);
      } else {
        setEmailInput('')
        if (isInEditMode) {
          updateRecipients([
            ...recipients,
            { email: emailInput, name: '' }
          ])
        }
      }
    }
  }

  const handleDeleteRecipient = (contact: IEmailRecipient) => {
    if (!isInEditMode) return;

    updateRecipients(
      recipients?.filter(recipient => contact.email !== recipient.email)
    )
  }

  return (
    <EmailHeaderLineLayout
      label={label}
      content={
        <div className="flex flex-wrap gap-1">
          {recipients?.map(contact => (
            <Badge
              key={contact.email}
              variant="secondary"
              className={cn("flex items-center gap-1 font-normal", isInEditMode && "font-semibold")}
            >
              {contact.email}

              {isInEditMode && (
                <button
                  onClick={() => handleDeleteRecipient(contact)}
                  className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 flex items-center"
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                    </TooltipTrigger>
                    <TooltipContent>Remove</TooltipContent>
                  </Tooltip>
                </button>
              )}
            </Badge>
          ))}

          {isInEditMode && (
            <Input
              className="h-auto rounded-md min-w-40 w-auto text-xs"
              placeholder={t('mailbox.enterEmailAndHitEnter') as string}
              value={emailInput}
              onChange={(e) => { setEmailInput(e.target.value) }}
              onKeyDown={handleAddingRecipient}
              onBlur={() => {
                if (emailInput) {
                  handleAddingRecipient({ key: 'Enter' } as React.KeyboardEvent);
                }
              }}
            />
          )}
        </div>
      }
    />
  );
}
