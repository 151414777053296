import { Separator } from "components/ui/separator"
import { useAppSelector, useTranslation } from "hooks"
import { selectSelectedThread } from "store/mailbox"
import { InfoCard } from "components/layout-atoms/InfoCard"
import { ScrollArea } from "components/ui/scroll-area"
import { ThreadDetailsViewer } from "./ThreadDetailsViewer"
import { ThreadDraftComposer } from "./ThreadDraftComposer"

export function ThreadDetailsPanel() {
  const { t } = useTranslation();

  const activeThread = useAppSelector(selectSelectedThread);
  const messages = activeThread?.messages || [];
  const drafts = activeThread?.drafts || [];

  return (
    <ScrollArea className="h-full w-full">
      {(drafts?.length === 0 && messages?.length === 0) ? (
        <InfoCard
          variant="item"
          title={t('mailbox.selectThread')}
          className="flex justify-center border-0"
        />
      ) : (

        <div className="flex h-ful flex-col">
          <div className="flex items-center px-4 py-2 min-h-14">
            <h2 className="text-xl">
              {messages?.[0]?.subject || drafts?.[0]?.subject}
            </h2>
          </div>

          <Separator />

          {messages?.length > 0 ? (
            <ThreadDetailsViewer />
          ) : (
            <ThreadDraftComposer />
          )}
        </div>
      )}
    </ScrollArea>
  );
}
