import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useMixpanel, useTranslation } from 'hooks';
import { CirclePlus } from 'lucide-react';
import { toast } from 'react-toastify';
import { cn } from 'lib/utils';
import { Card, CardHeader } from 'components/ui/card';
import { CardTitle } from 'components/ui/card';
import { CreateEditDataPointModal } from 'components/common/app/_general/CreateEditDataPointModal';
import { Loading } from 'components/layout-atoms/Loading';
import { ConfirmActionDialog } from 'components/common/_atoms/ConfirmActionDialog';
import { Fieldset } from 'components/ui/fieldset';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { ItemSelectedIcon } from 'components/common/_atoms/ItemSelectedIcon';
import { SearchInputDeprecated } from 'components/common/_atoms/SearchInputDeprecated';
import { DetailsLine } from 'components/common/_atoms/DetailsLine';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';
import { getDataPointsList, deleteDataPoint, IDataPoint, selectActiveDataPoint, selectDataPointActionUUID, selectDataPointsList, selectIsDataPointProcessing, setActiveDataPoint } from 'store/data-points';

export const DataPointsManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const [isCreateDataPointModalVisible, setIsCreateDataPointModalVisible] = useState(false)
  const [isEditDataPointModalVisible, setIsEditDataPointModalVisible] = useState(false)
  const [isDeleteDataPointDialogVisible, setIsDeleteDataPointDialogVisible] = useState(false)

  const [displayList, setDisplayList] = useState<IDataPoint[]>([])
  const [searchValue, setSearchValue] = useState('')

  const isDataPointProcessing = useAppSelector(selectIsDataPointProcessing)
  const activeDataPoint = useAppSelector(selectActiveDataPoint)
  const dataPointsList = useAppSelector(selectDataPointsList)
  const dataPointActionUUID = useAppSelector(selectDataPointActionUUID)

  useEffect(() => {
    dispatch(getDataPointsList())
  }, [dispatch, dataPointActionUUID])


  useEffect(() => {
    if (!searchValue) {
      setDisplayList(dataPointsList)
    } else {
      const lowerCaseValue = searchValue?.toLowerCase();

      setDisplayList(dataPointsList?.filter(item => {
        return [item?.name]?.find(item => item?.toLowerCase().includes(lowerCaseValue))
      }))
    }
  }, [dataPointsList, searchValue]);

  const handleDeleteDataPoint = async () => {
    if (activeDataPoint) {
      const response = await dispatch(deleteDataPoint(activeDataPoint));

      if (deleteDataPoint.fulfilled.match(response)) {
        setIsDeleteDataPointDialogVisible(false);
        toast(t('component.DataPointsManagement.deleteAction.success'))
      }
    }
  }


  return (
    <>
      <div className="grid grid-cols-[1fr_2fr] w-full h-full overflow-auto gap-6">
        <Fieldset
          className='basis-1/4'
          legend={t('component.DataPointsManagement.dataPoints.header')}
          fieldsetActions={[
            {
              onClick: () => setIsCreateDataPointModalVisible(true),
              tooltip: t('component.DataPointsManagement.dataPoints.tooltip'),
              content: <CirclePlus className='size-4' />
            }
          ]}
        >
          <SearchInputDeprecated
            onClearSearch={() => setSearchValue('')}
            onChange={(value) => setSearchValue(value)}
            containerClassName="p-0"
            inputPlaceholder={t('component.DataPointsManagement.dataPoints.searchPlaceholder') as string}
          />

          <div className="grow overflow-auto">
            <div className='flex flex-col gap-3'>
              {
                !isDataPointProcessing ?
                  <>
                    {
                      displayList?.map(dataPoint => (
                        <Card
                          key={dataPoint.id}
                          variant="item"
                          className={cn('cursor-pointer', { 'border-primary': dataPoint?.id === activeDataPoint?.id })}
                          onClick={() => dispatch(setActiveDataPoint(dataPoint))}
                        >
                          <CardHeader className="p-3">
                            <CardTitle className="text-sm">{dataPoint?.name}</CardTitle>
                          </CardHeader>
                          {dataPoint?.id === activeDataPoint?.id && (
                            <ItemSelectedIcon />
                          )}
                        </Card>
                      ))
                    }

                    {(!dataPointsList || dataPointsList?.length === 0) && (
                      <InfoCard
                        variant="item"
                        title=''
                        description={t('component.DataPointsManagement.info.noData') as string}
                      />
                    )}

                    {dataPointsList?.length > 0 && displayList?.length === 0 && (
                      <InfoCard
                        variant="item"
                        title=''
                        description={t('component.DataPointsManagement.info.noFilterData') as string}
                      />
                    )}
                  </>
                  : (
                    <Loading variant="item" />
                  )
              }
            </div>
          </div>
        </Fieldset>

        <div>
          {
            activeDataPoint && (
              <Fieldset
                className='grow-0 overflow-visible'
                legend={t('component.DataPointsManagement.details.header')}
              >
                <div className='flex flex-col gap-4'>
                  <div className="grid gap-3">
                    <DetailsLine displayOnSeparateLines={true} label={t('component.CreateEditDataPointModal.name')} value={activeDataPoint?.name} />
                  </div>
                  <Separator />

                  {activeDataPoint?.isEditable ? (
                    <div className="flex gap-3 justify-end">
                      <Button variant="destructive" onClick={() => setIsDeleteDataPointDialogVisible(true)}>
                        {t('component.DataPointsManagement.deleteAction.tooltip')}
                      </Button>
                      <Button onClick={() => {
                        setIsEditDataPointModalVisible(true)
                      }}>
                        {t('component.DataPointsManagement.editBtn')}
                      </Button>
                    </div>
                  ) : (
                    <InfoCard
                      variant="item"
                      title=''
                      description={t('component.DataPointsManagement.info.noEditable') as string}
                    />
                  )}
                </div>
              </Fieldset>
            )
          }
        </div>
      </div>

      <CreateEditDataPointModal isVisible={isCreateDataPointModalVisible} setIsVisible={setIsCreateDataPointModalVisible} />
      <CreateEditDataPointModal isVisible={isEditDataPointModalVisible} setIsVisible={setIsEditDataPointModalVisible} dataPoint={activeDataPoint} />

      <ConfirmActionDialog
        onOk={handleDeleteDataPoint}
        isVisible={isDeleteDataPointDialogVisible}
        setIsVisible={setIsDeleteDataPointDialogVisible}
        isProcessing={isDataPointProcessing}
        title={t('component.DataPointsManagement.deleteAction.title')}
        description={t('component.DataPointsManagement.deleteAction.description')}
      />
    </ >
  );
};