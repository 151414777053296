import { MailboxManager } from 'components/_mailbox/MailboxManager';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { MAILBOX_TYPE } from 'const';
export const MailboxPage = () => {
  return (
    <GeneralLayout isOneScreenOnly={true} contentClassName="!p-4">
      <div className="flex flex-1 overflow-auto gap-6 w-full max-w-6xl mx-auto rounded-[0.5rem] border bg-background shadow">
        <MailboxManager navCollapsedSize={4} type={MAILBOX_TYPE.INBOX} />
      </div>
    </GeneralLayout >
  );
};